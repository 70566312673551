@charset "UTF-8";
@import "ap_preaset";

/****************
***** reset *****
****************/

* {
  box-sizing: border-box;
}

html {
  height: 100%;
  font-size: toRem(16);
}

body {
  min-width: toRem(320);
  min-height: 100%;
}

html,
body,
header,
nav,
section,
article,
aside,
footer,
table,
th,
td,
div,
dl,
dt,
dd,
ol,
ul,
li,
a,
button,
input,
img,
select,
textarea,
fieldset,
form,
label,
legend,
h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
i,
b,
strong,
em,
sub,
figcaption,
figure {
  margin: 0;
  padding: 0;
  font-family: "Pretendard JP", sans-serif;
  font-style: normal;
  text-transform: none;
  vertical-align: baseline;
  background: transparent;
  border: 0;
}

:root {
  --On-View-onView2: #333d4b;
  --On-View-onView3: #6d7788;
  --Border-Neutral-border2: #edf0f5;
}

section,
article,
aside,
footer,
header,
nav,
hgroup {
  display: block;
}

table {
  width: 100%;
  font-size: inherit;
  border-collapse: collapse;
  /*     table-layout: fixed; */
}

strong {
  font-weight: 400;
}

audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline;
}

img {
  vertical-align: middle;
}

input,
label {
  vertical-align: middle;
}

input[type="submit"],
input[type="reset"],
input[type="button"],
button {
  display: inline-block;
  vertical-align: middle;
  font-size: 100%;
  cursor: pointer;
}

input[type="text"],
input[type="password"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

input[type="text"]::-ms-clear {
  display: none;
}

input[type="password"]::-ms-reveal {
  display: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button,
input[type="time"]::-webkit-inner-spin-button,
input[type="time"]::-webkit-outer-spin-button,
input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-outer-spin-button,
input[type="week"]::-webkit-inner-spin-button,
input[type="week"]::-webkit-outer-spin-button,
input[type="month"]::-webkit-inner-spin-button,
input[type="month"]::-webkit-outer-spin-button,
input[type="datetime-local"]::-webkit-inner-spin-button,
input[type="datetime-local"]::-webkit-outer-spin-button,
input[type="search"]::-webkit-inner-spin-button,
input[type="search"]::-webkit-outer-spin-button {
  margin: 0;
  appearance: none;
  -webkit-appearance: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-transition: background-color 9999s ease-out;
  -webkit-box-shadow: 0 0 0 toRem(1000) transparent inset !important;
}

i {
  margin: 0;
  padding: 0;
  display: inline-block;
  text-decoration: none;
  vertical-align: text-top;
}

a {
  display: inline-block;
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

a:visited,
a:focus,
a:hover,
input:focus,
button:focus,
textarea:focus,
select:focus {
  outline: none;
  text-decoration: none;
}

ol,
ul,
li {
  list-style: none;
}

hr {
  margin: 0;
  padding: 0;
  border: 0;
  border-top: toRem(1) solid $ap-line-gray-light;
}

/****************
***** common ****
****************/
.fltL {
  float: left;
}

.fltR {
  float: right;
}

/* scroll disable */
.hdd {
  overflow: hidden !important;
  touch-action: none;
}

/* clear */
.clear:after {
  content: "";
  display: block;
  clear: both;
}

/* text */
.ell {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: inherit;
}

.ell.line2 {
  white-space: normal !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 2 !important;
  -webkit-box-orient: vertical !important;
  word-wrap: break-word !important;
}

.taL {
  text-align: left !important;
}

.taC {
  text-align: center !important;
}

.taR {
  text-align: right !important;
}

.lineH {
  line-height: 30px;
}

th.ell,
td.ell {
  display: table-cell;
}

.brk {
  word-break: break-all;
}

.wordKeepAll {
  word-break: keep-all;
}

/* input & button */
input[type="text"],
input[type="password"],
input[type="file"],
input[type="email"],
input[type="number"],
input[type="tel"],
input[type="time"],
input[type="date"],
input[type="week"],
input[type="month"],
input[type="datetime-local"],
input[type="search"],
input[type="url"],
input[type="color"],
input {
  vertical-align: middle;
  font-size: 100%;
}

::placeholder {
  color: $ap-text-gray-2;
  font-weight: 100;
}

input[readonly],
pre.rdonly {
  background: $ap-white;
  font-weight: 700;
}

input[type="file"] {
  padding-left: 0;
  font-size: toRem(14);
}

input[type="color"] {
  width: toRem(35);
  cursor: pointer;
}

input[type="submit"],
input[type="reset"],
input[type="button"] {
  text-align: center;
}

textarea {
  width: 100%;
  min-height: toRem(30);
  padding: toRem(10);
  overflow: auto;
  background: $ap-white;
  border: toRem(1) solid $ap-text-gray-4;
  border-radius: toRem(3);
  resize: none;
}

pre {
  white-space: pre-line;
  line-height: toRem(20);
  color: $ap-text-gray-1;
}

.textCenter {
  text-align: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-center {
  justify-content: center;
}

.justify-start {
  justify-content: start;
}

.justify-end {
  justify-content: end;
}

.items-center {
  align-items: center;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.noBorder {
  border: none !important;
}

.pb-5 {
  padding-bottom: toRem(20);
}

.w-full {
  width: 100%;
}

@include rwd("sm") {
  .mobileHidden {
    display: none;
  }
}

.pcHidden {
  display: none;
}

@include rwd("md") {
  .pcHidden {
    display: block !important;
  }
}

/*************
*** event, promotion ***
*************/

#detailEventImage {
  img {
    max-width: 860px;
    width: 100% !important;
  }
}

/*************
*** select ***
*************/
.slctBox,
.inSlctBox {
  position: relative;
  display: inline-block;
}

.inSlctBox {
  z-index: 1;
}

.slctBox.w130 {
  min-width: toRem(130);
}

.slctBox.w200 {
  min-width: toRem(200);
}

.slctBox button.val,
.inSlctBox button.val {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: $ap-text-gray-1;
  font-size: toRem(16);
  font-weight: 500;
  line-height: 1.8rem;
}

.slctBox button.val > i,
.inSlctBox button.val > i {
  width: toRem(12);
  height: toRem(12);
  margin-left: toRem(10);
  overflow: hidden;
  text-indent: -9999px;
  background: url("https://com.airpremiacdn.net/resources/onepoint/images/arrow/btn_dropdown_able_up.svg") no-repeat center;
  transition: 0.2s;
}

.slctBox.on button.val > i,
.slctBox.on .inSlctBox.on button.val > i {
  transform: rotate(180deg);
}

.slctBox.on .inSlctBox button.val > i {
  transform: rotate(0deg);
}

.slctBox.on button.val.right > i {
  transform: rotate(-90deg);
}

.slctBox button.val.cc > i {
  margin-left: toRem(5);
}

.slctBox button.val .slct {
  color: $ap-text-black;
  text-align: left;
}

span.arNm.right.slct {
  text-align: right;
}

.slctBox > .slctCon,
.inSlctBox > .inSlctCon {
  min-width: toRem(160);
  max-height: toRem(224);
  padding: toRem(8) 0;
  position: absolute;
  top: toRem(30);
  left: 0;
  display: none;
  overflow-y: auto;
  background: $ap-white;
  border: toRem(1) solid $ap-text-black;
  border-radius: toRem(16);
  box-shadow: toRem(30) toRem(30) toRem(30) 0 rgb(0 0 0 / 3%);
  z-index: 101;
}

.slctCon.pop {
  max-height: unset;
  padding: 0;
  overflow: hidden;
}

.slctCon.lostPop {
  min-width: toRem(400);
}

.slctBox > .slctCon > li {
  width: 100%;
  padding: toRem(4) toRem(24);
  position: relative;
  text-align: left;
  white-space: nowrap;
  cursor: pointer;
}

.slctBox > .slctCon li:hover {
  background-color: $ap-text-gray-5;
}

.slctBox > .slctCon > li > span {
  width: 100%;
  padding: toRem(8) 0;
  font-weight: 500;
  font-size: toRem(16);
  line-height: toRem(24);
  text-align: left;
  white-space: nowrap;
  display: flex;
  align-items: center;
}

.slctBox > .slctCon > li.dis,
.slctBox > .slctCon > li.dis > span {
  color: $ap-text-gray-1;
  cursor: default;
}

.slctBox > .slctCon.hasIcon > li {
  padding: toRem(5) toRem(24);
  display: flex;
  align-items: center;
}

.slctBox > .slctCon.hasIcon > li > i {
  margin-right: toRem(6);
}

.slctBox.vtc {
  width: 100%;
  padding: toRem(16) 0;
  border-bottom: toRem(1) solid $ap-line-gray;
}

.slctBox.vtc button.val {
  font-size: toRem(18);
  line-height: initial;
}

.inSlctBox > .inSlctCon {
  min-width: toRem(120);
  top: toRem(30);
  left: toRem(-20);
}

.inSlctBox > .inSlctCon > li {
  width: 100%;
  padding: toRem(5) toRem(24);
  position: relative;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
}

.inSlctBox > .inSlctCon > li > span {
  width: 100%;
  height: 100%;
  padding: toRem(5) 0;
  display: block;
  font-weight: 500;
  line-height: 1.8;
  text-align: left;
  white-space: nowrap;
}

@include rwd("ul") {
  .slctBox.vtc {
    padding: toRem(12) 0;
  }
  .slctBox.vtc button.val {
    font-size: 1rem;
    line-height: 1.2em;
  }
}

@include rwd("xl") {
  .slctBox.vtc {
    padding: toRem(12) 0;
  }
  .slctBox.vtc button.val {
    font-size: 1rem;
    line-height: 1.2em;
  }
}

@include rwd("lg") {
  .slctBox.vtc {
    padding: toRem(12) 0;
  }
  .slctBox.vtc button.val {
    font-size: 1rem;
    line-height: 1.2em;
  }
}

@include rwd("sm") {
  /* select */
  .slctBox.w130 {
    width: 33.33%;
    min-width: unset;
  }
  .slctBox button.val,
  .inSlctBox button.val {
    font-size: toRem(16);
    font-weight: 500;
    line-height: 160%;
  }
  .slctCon.lostPop {
    min-width: toRem(200);
  }
  .fPopup .xBtnWrap {
    top: toRem(40);
    right: toRem(20) !important;

    &::after {
      right: toRem(0) !important;
    }
  }
}

@include rwd("xs") {
  .slctCon.lostPop {
    min-width: toRem(150);
  }
}

/******************
*** check/radio ***
******************/
.chkBox,
.rdoBox {
  padding-left: toRem(26);
  position: relative;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
}

.chkBox label,
.rdoBox label {
  vertical-align: middle;
  cursor: pointer;
}

.chkBox > input.ipt,
.rdoBox > input.ipt {
  width: toRem(18);
  height: toRem(24);
  position: absolute;
  left: 0;
  top: calc(50% - #{toRem(12)});
  background: none;
  opacity: 0;
  cursor: pointer;
  z-index: 1;
}

.chkBox:before,
.rdoBox:before {
  content: "";
  width: toRem(18);
  height: toRem(24);
  position: absolute;
  left: 0;
  top: calc(50% - #{toRem(12)});
  display: block;
}

.chkBox:before {
  background-image: url("https://com.airpremiacdn.net/resources/onepoint/images/icon/btn-check-03-inactive.svg");
}

.chkBox.on:before {
  background-image: url("https://com.airpremiacdn.net/resources/onepoint/images/icon/btn-check-03-active.svg");
}

.rdoBox:before {
  background-image: url("https://com.airpremiacdn.net/resources/onepoint/images/icon/btn_radio-01-inactive.svg");
}

.rdoBox.on:before {
  background-image: url("https://com.airpremiacdn.net/resources/onepoint/images/icon/btn_radio.svg");
}

.rdoBox {
  margin-right: toRem(30);
}

.rdoBox:last-child {
  margin-right: 0;
}

.chkBox.fullBtn {
  width: 100%;
  padding-left: 0;
  margin-bottom: toRem(10);
  font-weight: 700;
  border: toRem(1) solid $ap-text-gray-4;
  border-radius: toRem(12);
}

.chkBox.fullBtn:last-child {
  margin-bottom: 0;
}

.chkBox.fullBtn > input.ipt,
.chkBox.fullBtn:before {
  left: toRem(20);
}

.chkBox.fullBtn > label {
  width: 100%;
  padding: toRem(18) toRem(20) toRem(18) toRem(46);
  display: block;
}

.chkBox.fullBtn.on {
  background-color: $ap-beige-2;
  border-color: $ap-beige-1;
}

/********************
*** daterangeWrap ***
********************/
.slctBox > .slctCon .daterangeWrap ul > li:hover {
  background: transparent;
}

.daterangeWrap {
  //padding: toRem(30);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.daterangeWrap .calendarWrap {
  position: relative;
  display: flex;
  margin: toRem(32);
}

.daterangeWrap .calendarWrap .btnFix {
  position: absolute;
  padding: 0 toRem(8);
  top: toRem(7);
  display: flex;
  justify-content: space-between;
  z-index: 1;
}

.daterangeWrap .calendarWrap .calendar {
  flex-basis: 50%;
}

.daterangeWrap .calendarWrap .calendar .title {
  font-weight: 700;
  letter-spacing: toRem(-0.32);
  line-height: 1;
  text-align: center;
  padding-bottom: toRem(20);
}

.daterangefn_onClickOpenCalendarWrap .calendarWrap .calendar .list {
  margin-top: toRem(20);
}

.daterangeWrap .calendarWrap .calendar .list > ul {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.daterangeWrap .calendarWrap .calendar .list > ul > li {
  height: toRem(54);
  width: 100%;
  overflow: hidden;
  color: $ap-text-gray-4;
  line-height: 1.2em;
  font-weight: 700;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: toRem(2);
  padding-bottom: toRem(16);

  &.black {
    color: $ap-text-black;
  }

  &.clickable {
    cursor: pointer;
  }
}

.daterangeWrap .calendarWrap .calendar .list > ul > li.day {
  height: toRem(52);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: toRem(13);
  font-weight: 500;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.26px;
  color: $ap-text-black;
}

.daterangeWrap .calendarWrap .calendar .list > ul > li .calDate {
  font-size: toRem(16);
  font-style: normal;
  font-weight: 500;
  line-height: toRem(18);
  letter-spacing: toRem(-0.32);

  &.black {
    color: $ap-text-black;
  }
}

$base-color: #e6eaf0;
$shine-color: #f2f3f6;
$animation-duration: 2s;

@keyframes shine-lines {
  0% {
    background-position: -10vw 0px;
  }
  80%,
  100% {
    background-position: 10vw 0;
  }
}

.daterangeWrap .calendarWrap .calendar .list > ul > li .price {
  text-align: center;
  font-size: toRem(13);
  font-style: normal;
  font-weight: 400;
  line-height: toRem(18);
  letter-spacing: toRem(-0.22);

  &.ys {
    color: $ap-solid-skyblue;
  }

  &.pe {
    color: $ap-solid-orange;
  }

  &.soldOut {
    color: $ap-text-gray-1;
    cursor: default;
    font-size: toRem(11);
  }

  &.loading {
    margin: 0 auto;
    width: 18px;
    height: 11px;
    border-radius: 4px;

    background-image: linear-gradient(120deg, #e8e8e8 20%, #f3f3f3 28%, #e8e8e8 43%);
    background-size: 10vw 100%;
    animation: shine-lines $animation-duration alternate linear;
  }
}

.daterangeWrap .calendarWrap .calendar .list > ul > li.oneDay,
.daterangeWrap .calendarWrap .calendar .list > ul > li.startDay,
.daterangeWrap .calendarWrap .calendar .list > ul > li.on,
.daterangeWrap .calendarWrap .calendar .list > ul > li.endDay {
  position: relative;
  color: $ap-white;

  .calDate {
    color: $ap-white;
  }
}

.daterangeWrap .calendarWrap .calendar .list > ul > li.oneDay:after,
.daterangeWrap .calendarWrap .calendar .list > ul > li.startDay:after,
.daterangeWrap .calendarWrap .calendar .list > ul > li.on:after,
.daterangeWrap .calendarWrap .calendar .list > ul > li.endDay:after {
  content: "";
  width: 100%;
  height: toRem(18);
  position: absolute;
  display: block;
  background: $ap-point-orange;
  z-index: -1;
}

.loading {
  .daterangeWrap .calendarWrap .calendar .list > ul > li.oneDay:after,
  .daterangeWrap .calendarWrap .calendar .list > ul > li.startDay:after,
  .daterangeWrap .calendarWrap .calendar .list > ul > li.on:after,
  .daterangeWrap .calendarWrap .calendar .list > ul > li.endDay:after {
    background: none;
  }
}

.daterangeWrap .calendarWrap .calendar .list > ul > li.oneDay:after {
  border-radius: toRem(15);
}

.daterangeWrap .calendarWrap .calendar .list > ul > li.startDay:after {
  border-radius: toRem(15) 0 0 toRem(15);
}

.daterangeWrap .calendarWrap .calendar .list > ul > li.endDay:after {
  border-radius: 0 toRem(15) toRem(15) 0;
}

.daterangeWrap .calendarWrap .calendar .list > ul > li.startDay.endDay:after {
  border-radius: toRem(15);
}

.daterangeWrap .calendarWrap .calendar:first-child {
  margin-right: toRem(20);
}

.daterangeWrap .calendarWrap .calendarMonth {
  color: var(--ap-text-black, #222);
  font-size: toRem(18);
  font-style: normal;
  line-height: normal;
  font-weight: 700;
  letter-spacing: -0.36px;
}

.daterangeWrap .priceWrap {
  margin: toRem(12) 0;
  padding: toRem(32) toRem(32) 0 toRem(32);
  display: flex;
  justify-content: space-between;
  border-top: toRem(1) solid $ap-text-gray-4;
}

.daterangeWrap .priceWrap .sub,
.daterangeWrap .priceUnit {
  font-size: toRem(14);
  text-align: right;
}

.daterangeWrap .priceWrap .price {
  font-size: toRem(18);
  font-weight: 700;
  font-style: normal;
  line-height: 1;
  letter-spacing: toRem(-0.36);
  padding-bottom: toRem(1);
}

.daterangeWrap .priceWrap .price.ftO {
  font-size: toRem(18);
  font-weight: 700;
}

.daterangeWrap .priceWrap .price .unit {
  font-size: toRem(16);
  font-weight: 500;
  color: $ap-text-black;
}

.daterangeWrap .priceWrap .estimatedLowFare {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: toRem(8);
  padding-bottom: toRem(6);
}

.daterangeWrap .priceWrap .estimatedLowFare .sub {
  display: flex;
  height: toRem(20);
  padding: 2px 8px;
  justify-content: center;
  align-items: center;
  flex: inherit;

  gap: toRem(10);
  border-radius: 12px;
  color: white;
  background: var(--ap-point-orange, #ff5800);
  color: #fff;
  text-align: center;
  font-size: toRem(11);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.22px;
}

.daterangeWrap .priceWrap .left {
  display: flex;
  align-items: center;
  gap: toRem(20);
}

.daterangeWrap .priceWrap .left label {
  color: $ap-text-gray-1;
  text-align: center;
  font-size: toRem(13);
  font-style: normal;
  line-height: normal;
  letter-spacing: toRem(-0.24);
}

.daterangeWrap .priceWrap .left .image .fltImg {
  padding: 0 toRem(20);
}

.daterangeWrap .priceWrap .left > ul > li.sub {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: toRem(4);
  font-size: toRem(14);
  padding-bottom: toRem(7);
}

.daterangeWrap .priceWrap .left > ul > li.sub .date {
  color: $ap-text-black;
  line-height: normal;
  top: toRem(2);
  font-size: toRem(14);
  font-weight: 700;
}

.daterangeWrap .bottomArea {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 toRem(32) toRem(32) toRem(32);
}

.daterangeWrap .bottomArea .toggleBtn {
  width: calc(100% - #{toRem(250)});
  height: auto;
  position: relative;
  background-color: transparent;
  border: 0;
  border-radius: 0;
  box-shadow: none;
}

.dateWrapper .slctCon {
  width: toRem(800);
}

.dateWrapper .slctCon.sg {
  width: unset;
}

.daterangeWrap.sg .calendarWrap {
  display: block;
}

.daterangeWrap.sg .calendarWrap .calendar.loading,
.daterangeWrap .calendarWrap .calendar.loading {
  .list ul li.col {
    position: relative;

    a {
      display: none;
    }

    &:after {
      content: "";
      display: block;
      position: static;

      margin: 0 auto;
      width: 18px;
      height: 11px;
      border-radius: 4px;

      background-image: linear-gradient(120deg, #e8e8e8 20%, #f3f3f3 28%, #e8e8e8 43%);
      background-size: 10vw 100%;
      animation: shine-lines $animation-duration alternate linear;
    }
  }
}

.daterangeWrap.sg .calendarWrap .calendar:first-child {
  margin-right: 0;
}

.daterangeWrap.noPic .calendarWrap .calendar .list > ul > li a {
  line-height: 3em;
}

.dateWrapper .slctCon.once {
  width: toRem(400);
}

.fltConVtc .middle .dateWrapper .slctCon {
  top: toRem(-320);
}

.fltConVtc .middle .dateWrapper .slctCon.iNer {
  top: toRem(30);
  left: 0;
}

.bottomArea .taskButton {
  text-align: center;
}

.daterangeWrap .calendarWrap .calendar .list > ul > li a {
  height: 100%;
  display: block;
  color: $ap-text-black;
  line-height: 1.2em;
}

.daterangeWrap .calendarWrap .calendar .list > ul li {
  &.on,
  &.startDay,
  &.endDay,
  &.oneDay {
    a {
      color: $ap-white;
    }

    a:hover {
      color: $ap-white;
    }
  }
}

.daterangeWrap .calendarWrap .calendar .list > ul > li a:hover {
  color: $ap-solid-orange;
}

@include rwd("ul") {
  .fltConVtc .middle .dateWrapper .slctCon {
    top: toRem(-250);
  }
}

@include rwd("lg") {
  .daterangeWrap {
    height: 100%;
  }
  .dateWrapper .slctCon.once {
    width: 100%;
  }
  .daterangeWrap .topArea {
    height: calc(100% - #{toRem(120)});
    padding-top: toRem(60);
    overflow: hidden auto;
  }
  .daterangeWrap .bottomArea {
    width: 100%;
    height: toRem(100);
    padding: toRem(20) toRem(25);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    box-shadow: 0 toRem(-7) toRem(12) 0 rgba(0, 0, 0, 0.03);
  }
  .daterangeWrap .icon.arrow {
    width: toRem(20);
    height: toRem(20);
  }
  .daterangeWrap .calendarWrap .btnFix .btnCal.close {
    display: block;
  }
  .dateWrapper .slctCon {
    width: 100%;
    position: fixed;
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    inset: 0;
    border: 0;
    border-radius: 0;
    z-index: 111;
  }
  .daterangeWrap .priceWrap .sub .br {
    display: block;
  }
}

@include rwd("sm") {
  .daterangeWrap {
    padding: toRem(30) toRem(25);
  }
  .daterangeWrap .calendarWrap {
    display: block;
  }
  .daterangeWrap .calendarWrap .calendar:first-child {
    margin-right: 0;
  }
  .daterangeWrap .priceWrap {
    margin-bottom: toRem(20);
    padding-top: toRem(20);
  }
  .daterangeWrap .priceWrap .sub {
    text-align: left;
    flex-basis: calc(100% - #{toRem(115)});
  }
  .daterangeWrap .priceWrap .price.ftO {
    flex-basis: toRem(105);
  }
  .daterangeWrap .priceWrap .left {
    width: 100%;
    justify-content: space-around;
  }
  .daterangeWrap .priceWrap .sub,
  .daterangeWrap .priceWrap .left > ul > li.sub,
  .daterangeWrap .priceUnit {
    font-size: 0.75rem;
  }
  .daterangeWrap .priceWrap .price,
  .daterangeWrap .priceWrap .price.ftO {
    font-size: toRem(16);
  }
  .daterangeWrap .priceWrap .right {
    width: calc(100% - #{toRem(50)});
    position: absolute;
    bottom: toRem(100);
    left: toRem(25);
  }
  .daterangeWrap .priceWrap .right ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .daterangeWrap .bottomArea {
    height: toRem(150);
    padding-top: toRem(55);
    display: block;
  }
  .daterangeWrap .bottomArea .btnTwin {
    margin-top: toRem(10);
  }
  .daterangeWrap .topArea {
    height: calc(100% - #{toRem(120)});
  }
  .daterangeWrap .bottomArea .toggleBtn {
    width: 100%;
  }
}

@include rwd("xs") {
  .daterangeWrap {
    padding: toRem(30) toRem(15);
  }
  .daterangeWrap .calendarWrap .calendar .list > ul > li span {
    font-size: 0.75rem;
  }

  .daterangeWrap .priceWrap .left .image .fltImg {
    width: toRem(60);
    margin: 0;
    background-position: center;
  }
  /* .daterangeWrap .priceWrap .right ul {
      flex-direction: column;
  } */
  .daterangeWrap .priceWrap .mobBlk {
    display: block;
  }
  .daterangeWrap .topArea {
    height: calc(100% - #{toRem(150)});
  }
  .daterangeWrap .bottomArea {
    height: toRem(180);
    padding-top: toRem(100);
  }
  /* .daterangeWrap .priceWrap .sub {
      text-align: center;
  } */
  .daterangeWrap .priceWrap .sub .br .br2 {
    display: block;
  }
}

/************
*** table ***
*************/
.tbWrap {
  border-bottom: toRem(1) solid $ap-solid-darkblue;
  overflow-x: auto;
}

.tbWrap.mt30 {
  margin-top: toRem(30);
}

.tbWrap table {
  width: 100%;
  border-top: toRem(1) solid $ap-solid-darkblue;
}

.tbWrap .cpt {
  width: 100%;
  margin-bottom: toRem(5);
  display: block;
  font-size: toRem(14);
  text-align: right;
}

.tbWrap table th,
.tbWrap table td {
  padding: toRem(14) toRem(10);
  text-align: center;
  vertical-align: middle;
  word-break: break-all;
}

.tbWrap table td {
  border-right: toRem(1) solid $ap-text-gray-4;
}

.tbWrap table thead th,
.tbWrap table thead td {
  color: $ap-white;
  background: $ap-solid-darkblue;
  border-right: toRem(1) solid $ap-mobile-bg;
}

.tbWrap table th.taL,
.tbWrap table td.taL {
  text-align: left;
}

.tbWrap table thead td {
  border-right: toRem(1) solid $ap-point-blue-dark;
  border-bottom: toRem(1) solid $ap-point-blue-dark;
}

.tbWrap table tbody th {
  color: $ap-text-black;
  font-weight: 500;
  background: $ap-white;
  border-bottom: toRem(1) solid $ap-text-gray-4;
  border-right: toRem(1) solid $ap-solid-darkblue;
}

.tbWrap table tbody td {
  border-bottom: toRem(1) solid $ap-text-gray-4;
}

.tbWrap table thead th:last-child,
.tbWrap table thead td:last-child,
.tbWrap table tbody th:last-child,
.tbWrap table tbody td:last-child {
  border-right: 0;
}

.tbWrap table thead th.bdBN,
.tbWrap table thead td.bdBN,
.tbWrap table tbody th.bdBN,
.tbWrap table tbody td.bdBN,
.tbWrap table thead tr:last-child th,
.tbWrap table thead tr:last-child td,
.tbWrap table tbody tr:last-child th,
.tbWrap table tbody tr:last-child td {
  border-bottom: 0;
}

.tbWrap table thead th.lb,
.tbWrap table thead td.lb {
  border-color: $ap-point-blue-dark;
}

.tbWrap table tbody th.sb {
  border-right: toRem(1) solid $ap-text-gray-4;
}

.tbWrap table tbody th.nb {
  border-right: toRem(1) solid $ap-solid-darkblue;
}

.tbWrap table tbody th.db {
  border-bottom: toRem(1) solid $ap-solid-darkblue;
  border-right: toRem(1) solid $ap-text-gray-4;
}

.tbWrap table tbody th.db:last-child {
  border-right: 0;
}

.tbWrap table tbody td.sb,
.tbWrap table tbody td.sb:last-child {
  border-right: toRem(1) solid $ap-text-gray-4;
}

.tbWrap.vtc table tbody th {
  color: $ap-white;
  background: $ap-solid-darkblue;
  border-bottom-color: $ap-mobile-bg;
}

.tbWrap.vtc table tbody th.lb {
  border-color: $ap-point-blue-dark;
}

.tbTopBtn {
  margin-left: auto;
}

@include rwd("sm") {
  .tbWrap table {
    font-size: toRem(14);
  }
  .tbWrap .cpt {
    width: 100%;
    margin-bottom: toRem(5);
    display: block;
    font-size: toRem(14);
    text-align: right;
  }
  .tbWrap table th,
  .tbWrap table td {
    padding: toRem(14) toRem(10);
    text-align: center;
    vertical-align: middle;
  }
  .tbWrap table td {
    border-right: toRem(1) solid $ap-text-gray-4;
  }
  .tbWrap table thead th,
  .tbWrap table thead td {
    color: $ap-white;
    background: $ap-solid-darkblue;
    border-right: toRem(1) solid $ap-mobile-bg;
  }
  .tbWrap table th.taL,
  .tbWrap table td.taL {
    text-align: left;
  }
  .tbWrap table thead td {
    border-right: toRem(1) solid $ap-point-blue-dark;
    border-bottom: toRem(1) solid $ap-point-blue-dark;
  }
  .tbWrap table tbody th {
    color: $ap-text-black;
    font-weight: 500;
    background: $ap-white;
    border-bottom: toRem(1) solid $ap-text-gray-4;
    border-right: toRem(1) solid $ap-solid-darkblue;
  }
  .tbWrap table tbody td {
    border-bottom: toRem(1) solid $ap-text-gray-4;
  }
  .tbWrap table thead th:last-child,
  .tbWrap table thead td:last-child,
  .tbWrap table tbody th:last-child,
  .tbWrap table tbody td:last-child {
    border-right: 0;
  }
  .tbWrap table thead th.bdBN,
  .tbWrap table thead td.bdBN,
  .tbWrap table tbody th.bdBN,
  .tbWrap table tbody td.bdBN,
  .tbWrap table thead tr:last-child th,
  .tbWrap table thead tr:last-child td,
  .tbWrap table tbody tr:last-child th,
  .tbWrap table tbody tr:last-child td {
    border-bottom: 0;
  }
  .tbWrap table thead th.lb,
  .tbWrap table thead td.lb {
    border-color: $ap-point-blue-dark;
  }
  .tbWrap table tbody th.sb {
    border-right: toRem(1) solid $ap-text-gray-4;
  }
  .tbWrap table tbody th.nb {
    border-right: toRem(1) solid $ap-solid-darkblue;
  }
  .tbWrap table tbody th.db {
    border-bottom: toRem(1) solid $ap-solid-darkblue;
    border-right: toRem(1) solid $ap-text-gray-4;
  }
  .tbWrap table tbody th.db:last-child {
    border-right: 0;
  }
  .tbWrap table tbody td.sb,
  .tbWrap table tbody td.sb:last-child {
    border-right: toRem(1) solid $ap-text-gray-4;
  }
  .tbWrap.vtc table tbody th {
    color: $ap-white;
    background: $ap-solid-darkblue;
    border-bottom-color: $ap-mobile-bg;
  }
  .tbWrap.vtc table tbody th.lb {
    border-color: $ap-point-blue-dark;
  }
  table thead th,
  table thead td {
    padding: toRem(15) toRem(5);
  }

  .tbWrap table tbody th,
  .tbWrap table tbody td {
    padding: toRem(15) toRem(5);
  }
  .tbWrap.vtc table tbody th,
  .tbWrap.vtc table tbody td {
    padding-left: toRem(5);
    padding-right: toRem(5);
  }
}

/*****************
***** scroll *****
*****************/
/* scroll */
.scrllWrap {
  height: auto;
  position: relative;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.mCustomScrollBox {
  height: auto;
}

.mCS-minimal-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background: $ap-solid-darkblue;
}

.mCS-minimal-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  height: toRem(6);
  margin: toRem(2) 0;
  background: rgba(27, 43, 69, 0.3);
}

.mCS-autoHide > .mCustomScrollBox ~ .mCSB_scrollTools {
  opacity: 1 !important;
}

.mCSB_scrollTools.mCSB_scrollTools_horizontal,
.mCustomScrollBox.mCS-minimal-dark + .mCSB_scrollTools + .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  margin: 0;
}

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  height: toRem(6);
  margin: toRem(4) 0;
}

.mCSB_scrollTools,
.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCSB_scrollTools .mCSB_buttonUp,
.mCSB_scrollTools .mCSB_buttonDown,
.mCSB_scrollTools .mCSB_buttonLeft,
.mCSB_scrollTools .mCSB_buttonRight {
  transition: none;
  -webkit-transition: none;
  -moz-transition: none;
  -o-transition: none;
}

/*****************
*** line table ***
*****************/
.lineTb thead tr {
  border-bottom: toRem(2) solid $ap-line-black;
}

.lineTb tbody tr {
  border-bottom: toRem(1) solid $ap-line-gray;
}

.lineTb tbody tr:last-child {
  border-bottom: toRem(2) solid $ap-line-black;
}

.lineTb.hob tbody tr:hover {
  background-color: $ap-point-skyblue-light;
}

.lineTb thead th,
.lineTb thead td {
  height: toRem(40);
  padding: 0;
  color: $ap-text-gray-1;
  font-weight: 700;
}

.lineTb tbody td {
  height: toRem(100);
  vertical-align: middle;
}

.lineTb thead td,
.lineTb tbody th,
.lineTb tbody td {
  text-align: center;
}

.lineTb thead td:first-child,
.lineTb tbody td:first-child {
  text-align: left;
}

.lineTb.rlt {
  border-top: toRem(2) solid $ap-line-black;
  border-bottom: toRem(2) solid $ap-line-black;
}

.lineTb.rlt tbody tr:last-child {
  border-bottom: 0;
}

.lineTb.rlt tbody th,
.lineTb.rlt tbody td {
  height: auto;
  display: block;
  text-align: left;
}

.lineTb.rlt tbody th {
  padding: toRem(20) 0 toRem(15);
  font-weight: 400;
}

.lineTb.rlt tbody td {
  padding: 0 0 toRem(20);
  font-weight: 700;
}

@media only screen and (max-width: 374px) {
  .lineTb tbody td {
    word-break: break-word;
  }
  .lineTb tbody td:nth-child(2) {
    padding: 0 toRem(10);
  }
  .lineTb.rlt tbody th {
    padding: toRem(15) 0 toRem(10);
  }
  .lineTb.rlt tbody td,
  .lineTb.rlt tbody td:nth-child(2) {
    padding: 0 0 toRem(15);
  }
}

/*************
*** paging ***
*************/
.paging {
  margin-top: toRem(80);
}

.paging ul {
  display: flex;
  justify-content: center;
}

.paging ul li {
  width: toRem(30);
  height: toRem(30);
  margin-right: toRem(10);
}

.paging ul li:last-child {
  margin-right: 0;
}

.paging ul li a {
  display: block;
  color: $ap-text-black;
  font-size: toRem(14);
  font-weight: 500;
  text-align: center;
  border: toRem(2) solid transparent;
  border-radius: toRem(12);
  line-height: 1.4em;
}

.paging ul li a:hover {
  color: $ap-text-black;
  text-decoration: underline;
}

.paging ul li.on a {
  color: $ap-text-black;
  border: toRem(2) solid $ap-line-black;
}

.paging ul li.btnMv a {
  text-indent: -999em;
}

.paging ul li.btnMv.pre a {
  background: url("https://com.airpremiacdn.net/resources/onepoint/images/arrow/btn_prev_gray.png");
  background-repeat: no-repeat;
  background-position: center;
}

.paging ul li.btnMv.next a {
  background: url("https://com.airpremiacdn.net/resources/onepoint/images/arrow/btn_next_gray.png");
  background-repeat: no-repeat;
  background-position: center;
}

@include rwd("sm") {
  .paging {
    margin-top: toRem(60);
  }
}

/**********
*** tab ***
**********/
.tabBtn {
  display: flex;
}

.tabBtn.schBtt {
  margin-bottom: toRem(20);
}

.tabBtn.bdN {
  margin-bottom: 0;
}

.tabCont .cont {
  display: none;
}

.tabCont .cont.on {
  display: block;
}

.tabMenu.payDt .tabBtn {
  padding: 0 toRem(50);
}

.tabMenu.payDt .tabCont {
  margin-top: toRem(30);
  padding: toRem(40) toRem(50) toRem(50);
  border-top: toRem(1) solid $ap-line-gray;
}

.tabMenu.payDt .tabCont.pDn {
  padding: 0;
  border: 0;
}

.bd .tabBtn {
  margin-bottom: toRem(60);
}

.bd .tabBtn.mbn {
  padding: 0 toRem(30);
  margin-bottom: toRem(30);
}

.bd .tabBtn .btn {
  margin-right: toRem(30);
  position: relative;
  color: $ap-black;
  font-weight: 700;
}

.bd .tabBtn .btn.on {
  color: $ap-point-orange;
}

.bd .tabBtn .btn.on::after {
  content: "";
  width: 100%;
  height: toRem(2);
  position: absolute;
  bottom: toRem(-6);
  display: block;
  left: 0;
  background: $ap-point-orange;
}

.rud .tabBtn .btn {
  margin-right: 0;
  padding: 0 toRem(12);
  color: $ap-black;
  font-size: toRem(14);
  text-align: center;
  font-weight: 500;
  border-radius: toRem(60);
  cursor: pointer;
}

.rud .tabBtn .btn.on {
  color: $ap-white;
  background: $ap-solid-darkblue;
  padding-top: toRem(8);
  padding-bottom: toRem(8);
}

.rud .tabBtn .btn.on::after {
  display: none;
}

.rud .tabBtn .btn:first-child {
  padding-left: 0;
}

.rud .tabBtn .btn:first-child.on {
  padding-left: toRem(12);
}

@include rwd("sm") {
  .tabBtn {
    flex-wrap: wrap;
  }
  .rud .tabBtn.schBtt {
    margin-bottom: toRem(30);
  }
  .bd .tabBtn {
    margin-bottom: toRem(30);
    margin-left: -16px;
    margin-right: -16px;
    padding: 0 16px;
  }
  .bd .tabBtn.mbn {
    margin-bottom: toRem(20);
  }
  .bd .tabBtn .btn {
    font-size: toRem(14);
    margin-right: toRem(20);
    font-size: toRem(14);

    height: auto;
    padding-top: toRem(5);
    padding-bottom: toRem(5);
    margin-bottom: toRem(10);
  }
  .tabMenu.rud btn {
    height: auto;
    padding-top: toRem(5);
    padding-bottom: toRem(5);
  }
  .rud .tabBtn {
    margin-bottom: toRem(60);
  }
  .rud .tabBtn .btn {
    margin-right: 0;
    margin-bottom: toRem(10);
  }
  .tabMenu.payDt .tabBtn {
    padding: 0 toRem(25);
  }
  .tabMenu.payDt .tabCont {
    margin-top: 0;
    padding: toRem(40) toRem(25) toRem(50);
  }
}

/****************
**** default ****
****************/
html,
body {
  width: 100%;
  min-height: 100%;
  position: relative;
  color: $ap-text-black;
}

body {
  -webkit-font-smoothing: antialiased;
}

body,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-size: toRem(16);
  line-height: 1.6;
}

.comPd {
  //transition: max-width 0.3s linear;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 0 40px;

  max-width: 1680px;
  @include rwd("ul") {
    max-width: 1680px;
    padding: 0 40px;
  }
  @include rwd("2xl") {
    max-width: 1440px;
    padding: 0 40px;
  }
  @include rwd("xl") {
    max-width: 1280px;
    padding: 0 40px;
  }
  @include rwd("lg") {
    //max-width: 1024px;
    padding: 0 40px;
  }
  @include rwd("md") {
    padding: 0 16px;
  }
}

sub,
sup {
  position: relative;
  vertical-align: baseline;
  font-size: 90%;
}

.premia {
  font-family: "Premia", "Pretendard JP", sans-serif;
  font-weight: 400;
}

.premiaB {
  font-family: "Premia", "Pretendard JP", sans-serif;
  font-weight: 700;
}

.Heebo {
  font-family: "Heebo", "Pretendard JP", sans-serif;
  font-weight: 400;
}

.HeeboB {
  font-family: "Heebo", "Pretendard JP", sans-serif;
  font-weight: 700;
}

.ftG {
  color: $ap-text-gray-1 !important;
  font-weight: 300;
}

.ftB {
  color: $ap-solid-skyblue !important;
}

.ftO {
  color: $ap-point-orange !important;
}

.ftR {
  color: $ap-solid-red !important;
}

.ftK {
  color: $ap-text-black !important;
}

.fwB {
  font-weight: 700 !important;
}

.fwN {
  font-weight: 400 !important;
}

.textEnter {
  display: block;
}

.textEnterM {
  display: inline-block;
}

.wAt {
  width: auto;
}

.w100 {
  width: 100% !important;
}

.h40 {
  height: toRem(40);
}

.h100 {
  height: 100% !important;
}

.mg0 {
  margin: 0 !important;
}

.mt0 {
  margin-top: toRem(9);
}

.mt1 {
  margin-top: toRem(1);
}

.mb0 {
  margin-bottom: toRem(9);
}

.mb1 {
  margin-bottom: toRem(1);
}

.mb6 {
  margin-bottom: toRem(6);
}

.mb10 {
  margin-bottom: toRem(10);
}

.mb12 {
  margin-bottom: toRem(12);
}

.mb20 {
  margin-bottom: toRem(20);
}

.mb24 {
  margin-bottom: toRem(24);
}

.mb30 {
  margin-bottom: toRem(30);
}

.mb40 {
  margin-bottom: toRem(40);
}

.mb60 {
  margin-bottom: toRem(60);
}

.mb70 {
  margin-bottom: toRem(70);
}

.mb80 {
  margin-bottom: toRem(80);
}

.mt10 {
  margin-top: toRem(10);
}

.mt20 {
  margin-top: toRem(20);
}

.mt30 {
  margin-top: toRem(30);
}

.mt40 {
  margin-top: toRem(40);
}

.mt50 {
  margin-top: toRem(50);
}

.mt60 {
  margin-top: toRem(60);
}

.mt80 {
  margin-top: toRem(80);
}

.pd12 {
  padding-bottom: toRem(12);
}

.pdL4 {
  padding-left: toRem(4);
}

.pdL10 {
  padding-left: toRem(10);
}

.pdL20 {
  padding-left: toRem(20);
}

.pdL26 {
  padding-left: toRem(26);
}

.pdL30 {
  padding-left: toRem(30);
}

.gap-12 {
  gap: toRem(12);
}

.font-16 {
  font-size: toRem(16);
}

.bold {
  font-weight: 700;
}

@include rwd("lg") {
  .ftG {
    font-weight: 400;
  }
}

@include rwd("sm") {
  body,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: toRem(14);
    line-height: 1.6em;
  }
  .textEnter {
    margin-left: toRem(4);
    display: inline-block;
  }
  .textEnterM {
    margin-left: toRem(4);
    display: block;
  }
}

/*********************
*** linkBanner ***
*********************/

.linkBanner {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: toRem(64);
  padding: toRem(20) toRem(32);
  background: #edf0f5;
  border-radius: toRem(16);
}

.linkBannerText {
  font-size: toRem(16);
  font-weight: 500;
  color: #333d4b;
}

.linkBannerButton {
  font-size: toRem(14);
  font-weight: 500;
  color: #333d4b;
  padding: toRem(5) toRem(12);
  background: #ffffff;
  border-radius: toRem(10);
}

@include rwd("sm") {
  .linkBanner {
    display: block;
    margin-top: toRem(32);
    padding: toRem(20);
  }
  .linkBannerButton {
    margin-top: toRem(10);
  }
}

/***********
*** icon ***
************/
.iconWrap {
  position: relative;
  display: flex;
  align-items: center;
}

.iconWrap .txt.gray {
  color: $ap-text-gray-1;
}

.iconWrap .icon {
  margin-left: toRem(4);
}

.iconWrap.leftIcon .icon {
  margin-left: 0;
  margin-right: toRem(4);
}

.icon {
  display: block;
  overflow: hidden;
  text-align: left;
  text-indent: -9999px;
  cursor: pointer;
}

.icon.circleLg {
  width: toRem(14);
  height: toRem(14);
  background: url("https://com.airpremiacdn.net/resources/onepoint/images/arrow/btn_link_circle_lg.svg") no-repeat;
  background-size: toRem(14);
}

.icon.schWh {
  width: toRem(20);
  height: toRem(20);
  background: url("https://com.airpremiacdn.net/resources/onepoint/images/icon/btn_ico_search.svg") no-repeat center center;
  background-size: toRem(20);
}

.icon.arrowRLg {
  width: toRem(5);
  height: toRem(9);
  background: url("https://com.airpremiacdn.net/resources/onepoint/images/arrow/btn_dropdown_able_right_gray.svg") no-repeat;
}

.icon.info {
  width: toRem(16);
  height: toRem(16);
  background: url("https://com.airpremiacdn.net/resources/onepoint/images/icon/btn_info.svg") no-repeat;
}

.icon.arrow {
  width: toRem(16);
  height: toRem(16);
  margin-left: 0;
}

.icon.arrow.left {
  transform: rotate(180deg);
}

.icon.arrow.gray {
  background: url("https://com.airpremiacdn.net/resources/onepoint/images/arrow/btn_dropdown_arrow_gray.svg") no-repeat;
  background-size: cover;
}

.icon.arrow.blue {
  background: url("https://com.airpremiacdn.net/resources/onepoint/images/arrow/btn_dropdown_arrow_blue.svg") no-repeat;
  background-size: cover;
}

.icon.arrow.black {
  background: url("https://com.airpremiacdn.net/resources/onepoint/images/arrow/btn_dropdown_arrow_black.svg") no-repeat;
  background-size: cover;
}

.icon.arrow.orange {
  background: url("https://com.airpremiacdn.net/resources/onepoint/images/arrow/btn_dropdown_arrow_orange.svg") no-repeat;
  background-size: cover;
}

.icon.arrow.white {
  width: toRem(20);
  height: toRem(20);
  background: url("https://com.airpremiacdn.net/resources/onepoint/images/arrow/btn_dropdown_arrow_able_white.svg") no-repeat;
}

.icon.arrow.down {
  width: toRem(12);
  height: toRem(12);
  background: url("https://com.airpremiacdn.net/resources/onepoint/images/arrow/btn_dropdown_able_up.svg") no-repeat center;
}

.icon.caution {
  width: toRem(17);
  height: toRem(15);
  position: relative;
  top: toRem(2);
  background: url("https://com.airpremiacdn.net/resources/onepoint/images/util/ico-caution-gray.svg") no-repeat;
}

.description.iconWrap .icon.caution {
  position: absolute;
  top: toRem(4);
  left: 0;
}

.icon.arrNt {
  width: toRem(16);
  height: toRem(16);
  background: url("https://com.airpremiacdn.net/resources/onepoint/images/arrow/btn_dropdown_arrow_able_black_2.svg") no-repeat;
  transform: rotate(-90deg);
}

.icon.toggle {
  width: toRem(16);
  height: toRem(16);
  background: url("https://com.airpremiacdn.net/resources/onepoint/images/icon/ico_switch.svg") no-repeat;
}

.icon.xRound {
  width: toRem(16);
  height: toRem(16);
  background: url("https://com.airpremiacdn.net/resources/onepoint/images/icon/btn_delete.svg") no-repeat;
}

.icon.minus {
  background: url("https://com.airpremiacdn.net/resources/onepoint/images/util/ico-minus.svg");
}

.icon.plus {
  background: url("https://com.airpremiacdn.net/resources/onepoint/images/util/ico-plus.svg");
}

.icon.minus.on {
  background: url("https://com.airpremiacdn.net/resources/onepoint/images/util/ico-minus-on.svg");
}

.icon.plus.on {
  background: url("https://com.airpremiacdn.net/resources/onepoint/images/util/ico-plus-on.svg");
}

.icon.seat {
  width: toRem(34);
  height: toRem(34);
  background: url(https://com.airpremiacdn.net/resources/onepoint/images/support/ico_seat_black.svg) no-repeat;
  background-size: toRem(34);
}

.icon.restaurant {
  width: toRem(34);
  height: toRem(34);
  background: url(https://com.airpremiacdn.net/resources/onepoint/images/icon/ico_restaurant.svg) no-repeat;
  background-size: toRem(34);
}

.icon.specialmeal {
  width: toRem(34);
  height: toRem(34);
  background: url(https://com.airpremiacdn.net/resources/onepoint/images/icon/ico_specaialmeal.svg) no-repeat;
  background-size: toRem(34);
}

.icon.bag {
  width: toRem(34);
  height: toRem(34);
  background: url(https://com.airpremiacdn.net/resources/onepoint/images/icon/ico_bag_in.svg) no-repeat;
  background-size: toRem(34);
}

.icon.baggage {
  width: toRem(34);
  height: toRem(34);
  background: url(https://com.airpremiacdn.net/resources/onepoint/images/icon/ico_bag.svg) no-repeat;
  background-size: toRem(34);
}

.icon.preg {
  width: toRem(34);
  height: toRem(34);
  background: url(https://com.airpremiacdn.net/resources/onepoint/images/icon/ico_pregnant.svg) no-repeat;
  background-size: toRem(34);
}

.icon.dispers {
  width: toRem(34);
  height: toRem(34);
  background: url(https://com.airpremiacdn.net/resources/onepoint/images/icon/ico_disabledperson.svg) no-repeat;
  background-size: toRem(34);
}

.icon.oxy {
  width: toRem(34);
  height: toRem(34);
  background: url(https://com.airpremiacdn.net/resources/onepoint/images/icon/ico_oxygentank.svg) no-repeat;
  background-size: toRem(34);
}

.icon.car {
  width: toRem(34);
  height: toRem(34);
  background: url(https://com.airpremiacdn.net/resources/onepoint/images/support/ico_car.svg) no-repeat;
  background-size: toRem(34);
}

.icon.close {
  width: toRem(20);
  height: toRem(20);
  background: url(https://com.airpremiacdn.net/resources/onepoint/images/icon/ico_close.svg) no-repeat;
}

.icon.airportWh {
  width: toRem(25);
  height: toRem(25);
  background: url(https://com.airpremiacdn.net/resources/onepoint/images/support/ico_airport_white.png) no-repeat;
  background-size: toRem(25);
}

.icon.ticketWh {
  width: toRem(25);
  height: toRem(25);
  background: url(https://com.airpremiacdn.net/resources/onepoint/images/support/ico_ticket_white.png) no-repeat;
  background-size: toRem(25);
}

.icon.bagWh {
  width: toRem(25);
  height: toRem(25);
  background: url(https://com.airpremiacdn.net/resources/onepoint/images/support/ico_bag_white.png) no-repeat;
  background-size: toRem(25);
}

.icon.charge {
  width: toRem(26);
  height: toRem(26);
  background: url(https://com.airpremiacdn.net/resources/onepoint/images/icon/ico_charge.svg) no-repeat;
  background-size: toRem(26);
}

.icon.check {
  width: toRem(26);
  height: toRem(26);
  background: url(https://com.airpremiacdn.net/resources/onepoint/images/icon/ico_check.svg) no-repeat;
  background-size: toRem(26);
}

.icon.checkin {
  width: toRem(26);
  height: toRem(26);
  background: url(https://com.airpremiacdn.net/resources/onepoint/images/icon/ico_check_in.svg) no-repeat;
  background-size: toRem(26);
}

.icon.checkWh {
  width: toRem(25);
  height: toRem(25);
  background: url(https://com.airpremiacdn.net/resources/onepoint/images/support/ico_check_white.png) no-repeat;
  background-size: toRem(25);
}

.icon.seatWh {
  width: toRem(25);
  height: toRem(25);
  background: url(https://com.airpremiacdn.net/resources/onepoint/images/support/ico_seat_white.png) no-repeat;
  background-size: toRem(25);
}

.fltImg {
  width: toRem(100);
  height: toRem(20);
  margin: 0 toRem(8);
  background: url("https://com.airpremiacdn.net/resources/onepoint/images/icon/ico_flight_l.svg") no-repeat;
}

.fltImgL {
  width: toRem(140);
  height: toRem(20);
  margin: 0 toRem(40);
  background: url("https://com.airpremiacdn.net/resources/onepoint/images/icon/ico_flight.png") no-repeat;
}

.icon.sns.google {
  background: url(https://com.airpremiacdn.net/resources/onepoint/images/icon/ico_google.svg) no-repeat;
}

.icon.sns.facebook {
  background: url(https://com.airpremiacdn.net/resources/onepoint/images/icon/ico_facebook.svg) no-repeat;
}

.icon.sns.naver {
  background: url(https://com.airpremiacdn.net/resources/onepoint/images/icon/ico_naver.svg) no-repeat;
}

.icon.sns.kakao {
  background: url(https://com.airpremiacdn.net/resources/onepoint/images/icon/ico_kakao.svg) no-repeat;
}

.icon.snsB {
  width: toRem(60);
  height: toRem(60);
  background-size: cover;
}

.icon.snsB.google {
  background: url(https://com.airpremiacdn.net/resources/onepoint/images/icon/ico_google_b.svg) no-repeat;
}

.icon.snsB.facebook {
  background: url(https://com.airpremiacdn.net/resources/onepoint/images/icon/ico_facebook_b.svg) no-repeat;
}

.icon.snsB.naver {
  background: url(https://com.airpremiacdn.net/resources/onepoint/images/icon/ico_naver_b.svg) no-repeat;
}

.icon.snsB.kakao {
  background: url(https://com.airpremiacdn.net/resources/onepoint/images/icon/ico_kakao_b.svg) no-repeat;
}

.icon.notice {
  background: url(https://com.airpremiacdn.net/resources/onepoint/images/icon/ico_notice_g.svg) no-repeat;
}

.itinArr {
  width: toRem(22);
  height: toRem(22);
  margin: 0 toRem(10);
  display: block;
  position: relative;
  top: toRem(1);
  font-weight: 400;
}

.itinArr.rudWay {
  background: url("https://com.airpremiacdn.net/resources/onepoint/images/arrow/arrow_round_trip.svg") no-repeat center;
  background-size: contain;
}

.itinArr.oWay {
  background: url("https://com.airpremiacdn.net/resources/onepoint/images/arrow/arrow_one_way.svg") no-repeat center;
  background-size: contain;
}

.sub.iconWrap {
  padding-left: toRem(20);
  position: relative;
}

.sub.iconWrap .icon.caution {
  position: absolute;
  top: toRem(7);
  left: 0;
}

@include rwd("sm") {
  .fltImg {
    margin: 0;
  }
  .fltImgL {
    width: toRem(80);
    margin: 0 toRem(10);
    overflow: hidden;
    background-position: center;
  }
  .sub.iconWrap {
    font-size: toRem(14);
  }
  .sub.iconWrap .icon.caution {
    top: toRem(5);
  }
}

/******************
*** description ***
******************/
.description {
  padding: 0 toRem(10) 0 toRem(20);
  position: relative;
}

.description.ftG {
  font-size: toRem(14);
  line-height: 1.4em;
}

.description.iconWrap .icon.caution {
  position: absolute;
  top: toRem(2);
  left: 0;
}

.arrowLinkNext {
  width: toRem(20);
  height: toRem(20);
  background: url("https://com.airpremiacdn.net/resources/onepoint/images/arrow/btn_link_next.svg") no-repeat;
  background-size: toRem(20);
}

.empha {
  position: relative;
}

/*******************
*** position 공통 ***
*******************/
.pstT1 {
  position: relative;
  top: toRem(-1);
}

.pstT2 {
  position: relative;
  top: toRem(-2);
}

.pstT3 {
  position: relative;
  top: toRem(-3);
}

.pstT4 {
  position: relative;
  top: toRem(-4);
}

/***************
*** link 공통 ***
***************/
.link {
  cursor: pointer;
}

.link:hover {
  text-decoration: underline;
}

.link.bd {
  text-decoration: underline;
  display: inline;
}

.link.blank {
  position: relative;
}

.link.blank::after {
  content: "";
  width: 100%;
  height: toRem(1);
  display: block;
  position: absolute;
  bottom: toRem(1);
  left: 0;
  background: $ap-text-black;
}

.link.blank.ftB::after {
  background: $ap-solid-skyblue;
}

.link.blank.cN::after {
  background: none;
}

.linkText {
  display: inline-flex;
}

.linkText .txt:hover {
  text-decoration: underline;
}

.fileWrap .down a {
  color: $ap-solid-skyblue;
  font-weight: 700;
  word-break: break-all;
}

@include rwd("sm") {
  .teenLink {
    margin-top: toRem(2);
  }
}

/*************
*** 버튼링크 ***
*************/
.boxLnk {
  width: 100%;
  height: toRem(64);
  padding: 0 toRem(22) 0 toRem(30);
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 700;
  text-align: left;
  border-radius: toRem(8);
  border: toRem(1) solid $ap-text-gray-4;
  color: inherit;
}

.boxLnk.blck {
  border: toRem(1) solid $ap-line-black;
}

/************
*** fixBG ***
************/
.fixBG {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: none;
  background: rgba(0, 0, 0, 0.65);
  z-index: 299;
}

.fixCont {
  z-index: 300 !important;
}

/*****************
*** taskButton ***
*****************/
.taskButton {
  width: 100%;
  height: toRem(48);
  padding: 0 toRem(4);
  color: $ap-white;
  text-align: center;
  font-weight: 700;
  line-height: toRem(24);
  background-color: $ap-solid-darkblue;
  border-radius: toRem(8);

  @include rwd("sm") {
    font-weight: 500;
  }
}

.taskButton:hover {
  background-color: $ap-modern-2;
}

.taskButton.down,
.taskButton.arrow,
.taskButton.plus {
  padding: toRem(12) toRem(15);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.taskButton.down:after {
  content: "";
  width: toRem(14);
  height: toRem(14);
  margin-left: toRem(10);
  display: inline-block;
  background: url("https://com.airpremiacdn.net/resources/onepoint/images/icon/btn_download_wh.svg") no-repeat;
  background-size: toRem(14);
}

.taskButton.arrow:after {
  content: "";
  width: toRem(20);
  height: toRem(20);
  margin-left: toRem(10);
  display: inline-block;
  background: url("https://com.airpremiacdn.net/resources/onepoint/images/arrow/btn_link_white.svg") no-repeat;
  background-size: toRem(20);
}

.taskButton.plus:after {
  content: "";
  width: toRem(16);
  height: toRem(16);
  margin-left: toRem(10);
  display: inline-block;
  background: url("https://com.airpremiacdn.net/resources/onepoint/images/icon/ico_add_file_wh.svg") no-repeat;
  background-size: toRem(16);
}

.taskButton.line {
  color: $ap-text-gray-1;
  font-weight: 300;
  background-color: $ap-white;
  border: solid toRem(1) $ap-text-gray-2;
}

.taskButton.line:hover {
  background-color: $ap-white;
}

.taskButton.lineBk {
  color: $ap-text-black;
  font-weight: 300;
  background-color: $ap-white;
  border: solid toRem(1) $ap-line-black;
}

.taskButton.lineBk:hover {
  background-color: $ap-white;
}

.taskButton.text {
  padding: 0;
  height: auto;
  font-size: toRem(16);
  color: $ap-point-blue;
  background: none;

  &:hover {
    color: $ap-text-black;
  }
}

.taskButton.small {
  min-width: toRem(120);
  height: toRem(40);
  margin-right: toRem(10);
  font-size: toRem(14);
}

.taskButton.small:last-of-type {
  margin-right: 0;
}

.taskButton.gray {
  background-color: $ap-text-gray-4;
  font-weight: 400;
  cursor: default;
}

.taskButton.gray:hover {
  background-color: $ap-text-gray-4;
}

.taskButton.sky {
  color: $ap-white;
  background: $ap-solid-skyblue;
}

.taskButton.sky:hover {
  color: $ap-white;
  background: $ap-solid-skyblue-dark;
}

.taskButton.orange {
  color: $ap-white;
  background: $ap-solid-orange;
}

.taskButton.orange:hover {
  color: $ap-white;
  background: $ap-solid-orange-dark;
}

.taskButton.short {
  width: toRem(140);
}

.taskButton.active {
  color: $ap-white;
  background-color: $ap-solid-darkblue;
  cursor: pointer;
}

.taskButton.disable {
  background-color: $ap-text-gray-4;
  color: $ap-white;
  border: none;
  cursor: unset;
}

.taskButton.disable:hover {
  background-color: $ap-text-gray-4;
}

.taskButton.center {
  text-align: center;
}

.btnWhRd {
  width: auto;
  height: toRem(36);
  padding: 0 toRem(25);
  background: $ap-white;
  border-radius: toRem(20);
}

.btnWhRd:hover {
  color: $ap-solid-skyblue;
}

.btnTwin {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.btnTwin .taskButton {
  margin-right: toRem(10);
}

.btnTwin .taskButton:last-child {
  margin-right: 0;
}

.bottomBtn {
  margin-top: toRem(120);
  display: flex;
  align-items: center;
  justify-content: center;
}

.bottomBtn .taskButton {
  width: toRem(240);
  margin-right: toRem(10);
}

.bottomBtn .taskButton:last-child {
  margin-right: 0;
}

.bottomBtn.mt30 {
  margin-top: toRem(30);
}

.bottomBtn.mt0 {
  margin-top: 0;
}

.btnWhRd {
  width: auto;
  height: toRem(36);
  padding: 0 toRem(25);
  background: $ap-white;
  border-radius: toRem(20);
}

.btnWhRd:hover {
  color: $ap-solid-skyblue;
}

@include rwd("sm") {
  .taskButton {
    font-size: 1rem;
    font-weight: 500;
    letter-spacing: -0.032em;
  }
  .taskButton.small {
    padding: 0 toRem(10);
    min-width: toRem(80);
    font-size: toRem(14);
  }
  .taskButton.line {
    font-weight: 400;
  }
  .bottomBtn {
    flex-wrap: wrap;
  }
  .bottomBtn .taskButton {
    width: 100%;
    margin-right: 0;
    margin-bottom: toRem(15);
  }
  .bottomBtn .taskButton:last-child {
    margin-bottom: 0;
  }
  .btnWhRd {
    height: toRem(30);
    padding: 0 toRem(10);
    font-size: toRem(14);
  }
}

/****************
*** toggleBtn ***
****************/
.toggleBtn {
  position: relative;
  cursor: pointer;
}

.toggleBtn label {
  padding-left: toRem(52);
  position: relative;
  display: inline-block;
  font-size: toRem(14);
  font-weight: 500;
  line-height: toRem(28);
  cursor: pointer;
  z-index: 1;
}

.toggleBtn > input {
  width: toRem(42);
  height: toRem(4);
  position: absolute;
  top: toRem(12);
  left: 0;
  background: none;
  opacity: 0;
  cursor: pointer;
  z-index: 1;
}

.toggleBtn.on:before {
  background: $ap-point-orange;
}

.toggleBtn.on:after {
  left: toRem(23);
}

/* 슬라이드 다운 */
.tgList {
  cursor: pointer;
}

.tgList .tgCont {
  display: none;
}

/************
*** badge ***
************/
.badgeArrow {
  padding: toRem(1) toRem(8);
  position: relative;
  display: inline-block;
  line-height: 1.4em;
  background-color: $ap-solid-skyblue;
  border-radius: toRem(8) toRem(8) toRem(8) 0;
}

.badgeArrow.oRg {
  background-color: $ap-point-orange;
}

.badgeArrow p {
  color: $ap-white;
  font-weight: 700;
  letter-spacing: toRem(-0.32);
}

.badgeArrow .angle {
  position: absolute;
  left: toRem(-1);
  bottom: toRem(-6);
  display: block;
}

.badgeArrow .angle img {
  width: toRem(11);
  height: toRem(9);
}

.badgeArrow.rvs {
  border-radius: toRem(8) toRem(8) 0 toRem(8);
}

.badgeArrow.rvs .angle {
  left: inherit;
  right: toRem(-1);
  transform: scaleX(-1);
}

.badge {
  padding: toRem(2) toRem(8);
  color: $ap-white;
  font-size: toRem(15);
  background: $ap-text-light;
  border-radius: toRem(4);
}

.badge.flCbin {
  padding: toRem(2) toRem(10);
}

.badge.blue {
  background: $ap-point-blue;
}

.badge.black {
  background: $ap-text-black;
}

.badge.orange {
  background: $ap-point-orange;
}

@include rwd("lg") {
  .fileWrap .down a {
    font-size: toRem(16);
  }
}

@include rwd("sm") {
  .badge {
    font-size: toRem(14);
  }
  .badge.flCbin {
    height: 100%;
  }
}

/***************
*** imgRound ***
***************/
.imgRound {
  width: 100%;
  display: block;
  overflow: hidden;
  line-height: 1;
  background: $ap-line-light;
  border-radius: toRem(20);
}

.imgRound > img {
  width: 100%;
  height: auto;
}

@include rwd("md") {
  .imgRound {
    border-radius: toRem(10);
  }
}

/**************
*** btnBlue ***
**************/
.btnBlue {
  padding: toRem(5) toRem(15);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $ap-solid-skyblue;
  white-space: nowrap;
  font-size: toRem(16);
  background-color: $ap-white;
  border: toRem(1) solid $ap-solid-skyblue;
  border-radius: toRem(6);
}

.btnBlue:hover {
  text-decoration: underline;
}

.btnBlue.down:before {
  content: "";
  width: toRem(12);
  height: toRem(12);
  position: absolute;
  left: toRem(15);
  top: calc(50% - #{toRem(5)});
  display: block;
  background: url("https://com.airpremiacdn.net/resources/onepoint/images/icon/btn_download_blue.png") no-repeat;
}

.btnBlue.arrow {
  padding: 0;
  border: 0;
}

.btnBlue.prebtn {
  width: toRem(250);
  height: toRem(50);
  justify-content: flex-start;
}

.btnBlue.prebtn:hover {
  background: $ap-solid-skyblue;
}

.btnBlue.prebtn:hover a {
  color: $ap-white;
}

.btnBlue.arrow_box {
  padding: toRem(12) toRem(15);
  display: flex;
  align-items: center;
}

.btnBlue.arrow_box:after {
  content: "";
  width: toRem(8);
  height: toRem(11);
  margin-left: toRem(20);
  display: inline-block;
  background: url("https://com.airpremiacdn.net/resources/onepoint/images/arrow/btn_txt_link_blue.png") no-repeat;
  background-size: toRem(8);
}

.btnBlue.arrow_box:hover {
  color: $ap-white;
  background-color: $ap-solid-skyblue;
}

.btnBlue.arrow_box:hover:after {
  background: url("https://com.airpremiacdn.net/resources/onepoint/images/icon/arrow/btn_txt_link_white.png") no-repeat;
}

.btnBlue.white_r {
  color: $ap-white;
  background-color: $ap-solid-darkblue;
  border: toRem(1) solid $ap-white;
  float: right;
  clear: both;
}

.btnBlue.white_r:after {
  margin-left: toRem(80);
  background: url("https://com.airpremiacdn.net/resources/onepoint/images/icon/arrow/btn_txt_link_white.png") no-repeat;
}

.btnBlue.white_r:hover {
  border: toRem(1) solid $ap-solid-skyblue;
}

@include rwd("sm") {
  .btnBlue {
    font-size: toRem(14);
  }
  .btnBlue.down {
    padding: toRem(5) toRem(17) toRem(5) toRem(32);
  }
}

/******************
*** cautionWrap ***
******************/

.caution {
  .title {
    margin-bottom: toRem(16);
    padding-left: toRem(17);
    font-weight: 700;
    background: url("https://com.airpremiacdn.net/resources/onepoint/images/icon/ico_caution.svg") 0 toRem(7.5) no-repeat;
  }
}

.cautionRes {
  max-width: min(calc(100% - 400px), toRem(767));
  margin-top: toRem(60);
  margin-bottom: toRem(40);

  .list {
    z-index: -1;
  }
}

.grayLline {
  height: toRem(12);
  background: $ap-text-gray-6;
  margin-left: toRem(-40);
  width: calc(100% + 80px);
  margin-bottom: toRem(60);
}


.cautionWrap {
  margin-top: toRem(80);

  .title {
    margin-bottom: toRem(15);
    padding-left: toRem(17);
    line-height: toRem(24);
    background: url("https://com.airpremiacdn.net/resources/onepoint/images/icon/ico_caution.svg") no-repeat left;
  }
}

@include rwd("sm") {
  .grayLline {
    margin-bottom: toRem(40);
  }
  .paymentPrecaution {
    .grayLline {
      width: 100vw;
      margin-left: toRem(-25);
    }
  }
  .caution .title {
    margin-bottom: toRem(8);
    font-size: toRem(14);
    background-position-y: toRem(4);
  }
  .caution .list li {
    font-size: toRem(13) !important;
  }
}

.caution .list li,
.cautionWrap .list li {
  padding-left: toRem(16);
  position: relative;
  color: $ap-text-gray-1;
  font-size: toRem(14);
  line-height: toRem(22);
  font-weight: 300;
  word-break: break-word;
}

.caution .list li:before,
.cautionWrap .list li:before {
  content: "·";
  position: absolute;
  left: 0;
  top: 0;
}

.caution em.link {
  color: $ap-solid-skyblue;
  text-decoration: underline;
}

.cautionWrap .list li.star:before {
  content: "*";
}

.cautionWrap .list p,
.cautionWrap .list p span {
  font-size: toRem(14) !important;
}

.cautionWrap .cardCont {
  display: flex;
  justify-content: space-between;
}

.cautionWrap .cardCont .cardDt {
  width: 48%;
  padding: toRem(30);
  text-align: left;
  position: relative;
  background: $ap-white;
  border: toRem(1) solid $ap-text-gray-4;
  border-radius: toRem(8);
}

.cautionWrap .cardCont .cardDt .cardLft {
  width: toRem(215);
  height: toRem(142);
  margin: 0 auto;
}

.cautionWrap .cardCont .cardDt .cardLft img {
  width: 100%;
  height: 100%;
}

.cautionWrap .cardCont .cardDt .cardRgt {
  margin-top: toRem(30);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cautionWrap .cardCont .cardDt .cardRgt .cardTit {
  color: $ap-point-orange;
  font-size: toRem(20);
  font-weight: 600;
}

.cautionWrap .cardCont .cardDt .cardRgt .cardBd {
  margin-top: toRem(20);
  display: inline-block;
}

.cautionWrap .cardCont .cardDt .cardRgt p {
  margin-top: toRem(20);
  font-weight: 600;
}

.cautionWrap .cardCont .cardDt .cardRgt .cardVw {
  margin-top: toRem(20);
  padding: toRem(10) toRem(15);
  color: $ap-white;
  background: $ap-solid-darkblue;
  border: toRem(1) solid $ap-line-black;
  border-radius: toRem(8);
}

.cautionWrap .cardCont .buttonWrapper {
  width: toRem(160);
  margin: 0 auto;
}

/*이벤트 상세페이지*/
.cautionWrap.prmDtl {
  margin: 0 !important;
  overflow-x: auto;
}

.cautionWrap.prmDtl .list li {
  padding-left: 0 !important;
}

.cautionWrap.prmDtl .list li:before {
  display: none;
}

.cautionWrap.prmDtl .list li ul li {
  padding-left: toRem(10) !important;
}

.cautionWrap.prmDtl .list li ul li:before {
  content: "-";
  position: absolute;
  left: 0;
  top: 0;
  display: block;
}

.cautionWrap.prmDtl .list p {
  padding-left: toRem(10) !important;
  position: relative;
}

.paymentPrecaution {
  .link.bd {
    font-size: toRem(14);
  }
}

@include rwd("md") {
  .cautionRes {
    max-width: 100%;
  }
}

@include rwd("sm") {
  .cautionWrap {
    margin-top: toRem(60);
  }
  .cautionWrap .title {
    font-size: toRem(14);
  }
  .cautionWrap .list li {
    font-size: toRem(14);
    font-weight: 400;
  }
  .cautionWrap .list p,
  .cautionWrap .list p span {
    font-size: toRem(13) !important;
    font-weight: 400;
  }
  .cautionWrap .cardCont {
    flex-direction: column;
  }
  .cautionWrap .cardCont .cardDt {
    width: 100%;
    margin-bottom: toRem(20);
  }
  .cautionWrap .cardCont .cardDt:last-child {
    margin-bottom: 0;
  }
  .cautionWrap .cardCont .cardDt .cardLft {
    width: toRem(180);
    height: toRem(113);
  }
  .cautionWrap .cardCont .cardDt .cardRgt .cardTit {
    font-size: 1.8rem;
  }
  .cautionRes {
    margin: toRem(40) 0px;
  }
  .paymentPrecaution .link.bd {
    font-size: toRem(13);
  }
}

/*****************
*** oneLineBox ***
*****************/
.oneLineBox {
  padding: toRem(12) toRem(20);
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $ap-text-gray-5;
  border: solid toRem(1) $ap-text-gray-4;
  border-radius: toRem(8);
}

.oneLineBox .txt {
  font-weight: 700;
  letter-spacing: toRem(-0.28);
}

/*******************
*** inputWrapper ***
*******************/
.txtIpCont {
  width: 100%;
  position: relative;
  border-bottom: solid toRem(1) $ap-line-black;
}

.inputWrapper {
  width: 100%;
  height: toRem(40);
  position: relative;
}

.inputWrapper.tb {
  display: table;
}

.inputWrapper .psBox {
  padding: 0;
  display: flex;
}

.inputWrapper.hAto {
  height: auto;
}

.inputWrapper input,
.inputWrapper .txt {
  width: 100%;
  height: 100%;
  font-size: 1rem;
  font-weight: 700;
}

.inputWrapper .txt {
  display: table-cell;
  vertical-align: middle;
}

.inputWrapper .iptLb {
  margin-bottom: toRem(10);
  display: block;
}

.inputWrapper .iptTxt {
  height: toRem(40);
  position: relative;
  border-bottom: toRem(1) solid $ap-line-gray;
}

.inputWrapper input::placeholder {
  position: relative;
  font-weight: 400;
}

.inputWrapper .pwToggle {
  width: toRem(20);
  height: toRem(20);
  display: inline-block;
  position: absolute;
  right: 0;
  top: toRem(10);
  cursor: pointer;
}

.inputWrapper .pwToggle.pwInvisible {
  background: url("https://com.airpremiacdn.net/resources/onepoint/images/icon/auth/pw_invisible.png");
  background-size: cover;
}

.inputWrapper .pwToggle.pwVisible {
  background: url("https://com.airpremiacdn.net/resources/onepoint/images/icon/auth/pw_visible.png");
  background-size: cover;
}

.inputWrapper.flex .divideLine {
  width: toRem(1);
  height: toRem(18);
  margin: 0 toRem(10);
  display: block;
  background: $ap-text-gray-4;
}

.inputWrapper .inputLabel {
  font-size: toRem(14);
}

@include rwd("sm") {
  .inputWrapper input {
    font-size: toRem(14);
  }
}

/****************
*** inputWrap ***
****************/
.inputWrap .rwtWc {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

button {
  vertical-align: baseline;
}

.inputWrap .row {
  padding: toRem(18) 0;
  position: relative;
  display: flex;
  align-items: center;
  letter-spacing: toRem(-0.3);
  line-height: toRem(24);
  border-bottom: toRem(1) solid $ap-text-gray-4;
}

.inputWrap .row:last-child {
  margin-bottom: 0;
}

.inputWrap .row.bdN {
  border: 0;
}

.inputWrap .row.haLf {
  width: calc(50% - #{toRem(10)});
}

.inputWrap .row .label {
  width: toRem(160);
}

.inputWrap .row .value {
  padding-left: toRem(40);
  position: relative;
  display: flex;
  align-items: center;
  flex: 1 1 0%;
  font-weight: 700;
}

.inputWrap .row .value.taR {
  justify-content: flex-end;
}

.inputWrap .row .value.block {
  display: block;
}

.inputWrap .row .value .num {
  margin: 0 toRem(6);
}

.inputWrap .row .value.flex {
  display: flex;
}

.inputWrap .row .value.flex .fBtn {
  position: absolute;
  top: toRem(5);
  right: 0;
}

.inputWrap .row .value .divideLine {
  width: toRem(1);
  height: toRem(18);
  margin: 0 toRem(10);
  display: block;
  background: $ap-text-gray-4;
}

.inputWrap .row .value .txt {
  width: 100%;
}

.inputWrap .row .value input {
  width: 100%;
}

.inputWrap .row.total .label {
  font-weight: 700;
}

.inputWrap .row.total .value {
  color: $ap-point-orange;
  font-size: toRem(20);
  font-weight: 700;
  text-align: right;
}

.inputWrap .lgMsg {
  padding-left: toRem(200);
  position: static;
}

.inputWrap .row.euWarp {
  justify-content: space-between;
}

@media only screen and (max-width: 1199px) {
  .inputWrap .rwtWc {
    display: block;
  }
  .inputWrap .row.haLf {
    width: 100%;
  }
}

@include rwd("sm") {
  .inputWrap .row {
    height: auto;
    margin: toRem(20) 0 toRem(5) 0;
    padding: 0;
    display: block;
    flex-direction: column;
    align-items: baseline;
  }
  .inputWrap .row.pssPt {
    padding-bottom: toRem(10);
  }
  .inputWrap .row .label {
    width: 100%;
    margin-right: toRem(5);
    font-size: toRem(14);
  }
  .inputWrap .row .value {
    width: 100%;
    height: toRem(40);
    padding-left: 0;
  }
  .inputWrap .row .value.mChk {
    flex-direction: column;
  }
  .inputWrap .row .value.dpBck .chkBox {
    width: 100%;
  }
  .inputWrap .row .value.hAuto {
    height: auto;
    padding: toRem(10) 0;
  }
  .inputWrap .row .value .slctBox {
    width: 100%;
    min-width: toRem(80);
  }
  .inputWrap .row .value .slctBox.psBox {
    width: auto;
  }
  .inputWrap .row.total .value {
    font-size: toRem(18);
  }
  .inputWrap .lgMsg {
    padding-left: 0;
  }
}

@media only screen and (max-width: 479px) {
  .inputWrap .row .value.dpBck {
    height: toRem(60);
    flex-direction: column;
    align-items: baseline;
  }
}

/********************
*** infoInputWrap ***
*********************/
.infoInputWrap {
  padding: toRem(60) 0;
  border-top: toRem(2) solid $ap-line-black;
  border-bottom: toRem(2) solid $ap-line-black;
}

.infoInput {
  padding: toRem(60) 0;
  display: flex;
  border-bottom: toRem(1) solid $ap-text-gray-4;
}

.infoInput:first-child {
  padding-top: 0;
}

.infoInput:last-child {
  padding-bottom: 0;
  border-bottom: 0;
}

.infoInput .infoTtl {
  margin-right: toRem(100);
  flex-basis: toRem(220);
}

.infoInput .infoTtl h3 {
  font-size: toRem(24);
  font-weight: 700;
}

.infoInput .inputWrap {
  flex: 1 1 0;
}

.infoInput .dfTxt.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@include rwd("lg") {
  .infoInputWrap .infoInput .inputWrap.flex {
    display: block;
  }
  .infoInputWrap .infoInput .inputWrap.flex .rdoWrap {
    margin-top: toRem(10);
  }
}

//없는 bp....
@media only screen and (max-width: 800px) {
  .infoInputWrap {
    padding: toRem(30) 0;
  }
  .infoInput {
    padding: toRem(30) 0;
    flex-direction: column;
    border-bottom: 0;
  }
  .infoInput .inputWrap {
    flex: 1 1 auto;
  }
  .infoInput .infoTtl {
    margin-right: 0;
    margin-bottom: toRem(20);
    flex-basis: auto;
  }
}

@include rwd("sm") {
  .infoInput .infoTtl h3 {
    font-size: toRem(18);
  }
  .number {
    font-size: toRem(14);
  }
}

@include rwd("xs") {
  .infoInput .inputWrap input {
    height: 100%;
    height: toRem(40);
  }
  .inputWrap .lgMsg {
    padding-left: 0;
  }
}

/**************
*** 경고메세지 ***
***************/
.lgMsg {
  position: absolute;
  bottom: toRem(-26);
  color: $ap-solid-red;
  font-size: toRem(14);
  z-index: 2;
}

.lgMsg.stat {
  position: static;
}

/*****************
*** sendButton ***
******************/
.sendButton {
  width: auto;
  height: toRem(28);
  padding: 0 toRem(10);
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $ap-text-black;
  font-size: toRem(14);
  font-weight: 700;
  background-color: $ap-white;
  border: solid toRem(1) $ap-text-gray-4;
  border-radius: toRem(30);
  cursor: pointer;

  &.disable {
    color: $ap-text-gray-4;
    background: $ap-text-gray-5;
    border-radius: toRem(15);
    border: toRem(1) solid $ap-text-gray-4;
    cursor: default;
  }
}

.sendButton.nBtn {
  position: static;
}

.sendButton.static {
  margin-left: toRem(10);
  position: static;
  white-space: nowrap;
}

.sendButton.static:first-of-type {
  margin-left: 0;
}

.sendButton.moBtn {
  display: none;
}

/*****************
*** lineButton ***
******************/
.lineButton {
  width: auto;
  min-width: toRem(38);
  height: toRem(38);
  padding: toRem(4) toRem(10);
  display: flex;
  justify-content: center;
  align-items: center;
  color: $ap-text-black;
  font-size: toRem(13);
  font-weight: 500;
  background-color: $ap-white;
  border: solid toRem(1) $ap-text-light;
  border-radius: toRem(8);
  cursor: pointer;
  white-space: nowrap;
  gap: toRem(6);

  &:hover {
    background-color: $ap-text-gray-5;
  }
}

/************
*** guide ***
************/
.guide {
  height: toRem(28);
  position: absolute;
  top: toRem(5);
  right: 0;
}

/***************
*** xBtnWrap ***
***************/
.xBtnWrap {
  height: inherit;
  position: absolute;
  top: toRem(13);
  right: 0;
  display: block;
}

.xBtnWrap img {
  width: 100%;
}

/***************
**** btnCal ****
***************/
.btnCal.close {
  position: fixed;
  top: toRem(10);
  right: toRem(10);
  display: none;
}

@include rwd("lg") {
  .btnCal.close button {
    padding: toRem(10);
  }
}

/****************
**** tckCard ****
****************/
.tckCardWrap {
  margin-top: toRem(20);
  padding: 40px;
  border: toRem(1) solid $ap-text-gray-4;
  border-radius: toRem(12);
}

.tckCardWrap:first-child {
  margin-top: 0;
}

.tckCardWrap.black {
  border-color: $ap-line-black;
}

.tckCardWrap .title {
  font-size: toRem(22);
  font-weight: 700;
}

.tckCardWrap .title sub {
  margin-bottom: toRem(10);
  display: block;
  color: $ap-text-gray-1;
  font-size: 0.8em;
  font-weight: 400;
}

.tckCard {
  padding: toRem(20);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tckCard.left {
  padding: 0;
  justify-content: flex-start;
}

.tckCard > li {
  min-width: toRem(120);
  margin-right: toRem(100);
}

.tckCard > li:last-child {
  margin-right: 0;
}

.tckCard .val {
  font-weight: 700;
}

.tckTotal {
  margin-top: toRem(40);
  padding-top: toRem(40);
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: toRem(2) dashed $ap-text-gray-4;
}

.tckTotal .label {
  font-weight: 700;
}

.tckTotal .val {
  color: $ap-point-orange;
  font-size: toRem(20);
  font-weight: 700;
}

.tckTotal .val .num {
  margin-right: toRem(6);
}

//없는 bp...
@media only screen and (max-width: 1365px) {
  .tckCard > li {
    min-width: toRem(80);
    margin-right: toRem(60);
  }
}

@include rwd("lg") {
  .tckCardWrap {
    padding: toRem(25);
  }
  .tckCard,
  .tckCardWrap.flex {
    display: block;
  }
  .tckCardWrap .tckCard {
    margin-top: toRem(60);
  }
  .tckCard .column,
  .tckCard > li {
    margin-top: toRem(20);
  }
  .tckCard .column:first-child {
    margin-top: 0;
  }
}

@include rwd("sm") {
  .tckCardWrap .title {
    font-size: 1.8rem;
  }
}

//없는 bp...
@media only screen and (max-width: 479px) {
  .sendButton.moNone {
    display: none;
  }
  .sendButton.moBtn {
    width: 100%;
    margin-top: toRem(40);
    position: inherit;
    display: block;
  }
}

/*************
*** bPopup ***
*************/
.bPopWrap {
  width: calc(100% - #{toRem(100)});
  min-width: toRem(280);
  max-width: toRem(640);
  background: $ap-white;
  border-radius: toRem(16);
  overflow: hidden;
  display: none;

  &.wdCtr {
    max-width: 100%;
  }

  &.w420 {
    max-width: toRem(420);
  }

  &.w500 {
    max-width: toRem(500);
  }

  &.w520 {
    max-width: toRem(520);
  }

  &.w600 {
    max-width: toRem(600);
  }

  &.w640 {
    max-width: toRem(640);
  }

  &.w800 {
    max-width: toRem(800);
  }

  &.w820 {
    max-width: toRem(820);
  }

  &.w1200 {
    max-width: toRem(1200);
  }

  &.bottomed-sm {
    @include rwd("sm") {
      position: fixed;
      bottom: 0;
      left: 0;
      top: auto !important;
      width: 100%;
      height: auto;
      max-width: 100%;
      min-width: 100%;
      margin: 0;
      border-radius: toRem(24) toRem(24) 0 0;
    }
  }

  .popTit {
    padding: toRem(40) toRem(48);

    &.pdN {
      padding-bottom: 0;
    }
  }

  .content-gap {
    height: toRem(40);
    @include rwd("sm") {
      &.smh24 {
        height: toRem(24);
      }
      &.smh28 {
        height: toRem(20);
      }
    }
  }
}

.pTit {
  padding-right: toRem(40);
  color: $ap-text-black;
  font-size: toRem(24);
  font-weight: 700;
  line-height: 160%;
}

.pTit .popDescription {
  margin-top: toRem(10);
  display: block;
  color: $ap-text-gray-1;
  font-size: toRem(16);
  line-height: 180%;
  font-weight: 400;
}

.pTit .pSbTit {
  margin-top: toRem(10);
  display: block;
  font-size: toRem(16);
  line-height: 1.6em;
}

.pTit.aNth {
  height: auto;
  margin-bottom: toRem(50);
}

.bPopWrap .xBtnWrap {
  width: toRem(20);
  height: toRem(20);
  display: flex;
  position: absolute;
  top: toRem(45);
  right: toRem(50);
}

.xBtnWrap .b-close {
  width: 100%;
  height: 100%;
  text-indent: -999em;
  position: relative;
}

.xBtnWrap .b-close::before,
.xBtnWrap .b-close::after {
  content: "";
  height: toRem(20);
  width: toRem(2);
  position: absolute;
  left: toRem(8);
  top: toRem(3);
  background: $ap-text-black;
}

.xBtnWrap.wht .b-close::before,
.xBtnWrap.wht .b-close::after {
  background: $ap-white;
}

.xBtnWrap .b-close::before {
  transform: rotate(45deg);
}

.xBtnWrap .b-close::after {
  transform: rotate(-45deg);
}

.bPopWrap .popScr {
  max-height: toRem(400);
  min-height: toRem(100);
  padding: 0 toRem(48) toRem(40) toRem(48);
  overflow-y: auto;
  overflow-x: hidden;
}

.bPopWrap .popScr .mTxt {
  font-weight: 700;
}

.bPopWrap .popScr.hAuto {
  max-height: unset;
  overflow-y: visible;
}

.bPopWrap .popScr.hShort {
  min-height: toRem(50);
}

.bPopWrap .popScr .contents {
  margin-top: toRem(30);
}

.bPopWrap .popScr .contents:first-child {
  margin-top: 0;
}

.bPopWrap .popScr.pN {
  padding: 0;
}

.bPopWrap .popScr.prM {
  padding: 0 toRem(50) toRem(50);
}

.bPopWrap .popScr .popBtm.unli {
  margin-top: toRem(80);
  padding: 0;
  border-top: 0;
}

.bPopWrap .popBtm {
  padding: toRem(40) toRem(48);
  border-top: toRem(1) solid $ap-line-gray;

  &.small {
    padding: toRem(16) toRem(24);
  }
}

.bPopWrap .popBtm.noBorder {
  padding: toRem(25) toRem(50) toRem(50);
  border-top: none;
}

.bPopWrap .popBtm .link.bd {
  margin-top: toRem(80);
}

.bPopWrap.h85 {
  height: calc(100vh - #{toRem(85)});
}

.bPopWrap.h85 .popTit {
  height: toRem(150);
  padding-bottom: 0;
}

.bPopWrap.h85 .popScr {
  max-height: calc(100% - #{toRem(251)});
}

.bPopWrap .btnWrap {
  margin-top: toRem(20);
}

.bPopWrap.alertDialog {
  max-width: toRem(320);

  .popScr {
    padding: toRem(40) toRem(32);
    font-size: toRem(18);
    text-align: center;
    font-weight: 700;
    line-height: 160%;
  }
}

/* 회사소개 팝업 - 이미지 팝업 */
.bPopWrap.fulPop {
  position: relative;
  overflow: inherit;
}

.fulPopCon {
  border-radius: toRem(16);
  overflow: hidden;
}

.bPopWrap.fulPop .popScr {
  max-height: toRem(600);
  min-height: toRem(100);
  padding: 0;
  overflow-y: auto;
  overflow-x: hidden;
}

.popImg img {
  width: 100%;
}

.bPopWrap.fulPop .xBtnWrap {
  position: absolute;
  top: toRem(-35);
  right: 0;
}

.noShow {
  position: absolute;
  bottom: toRem(-32);
  right: toRem(5);
}

.noShow button {
  color: $ap-white;
}

@include rwd("sm") {
  .noShow {
    bottom: toRem(-30);
    right: 50%;
    transform: translateX(50%);
  }
  .bPopWrap.fulPop .popScr {
    max-height: toRem(460);
  }
}

/* toast */
.bPopWrap.toast {
  max-width: toRem(360);
}

.bPopWrap.toast .popTit {
  display: flex;
  padding: 20px 20px 24px 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}

.bPopWrap.toast .pTit {
  padding-right: 0;
  font-size: toRem(20);
  text-align: left;
  white-space: pre-line;

  color: var(--On-View-onView1, #171a22);
  line-height: 30px; /* 150% */
}

.bPopWrap.toast .popBtm {
  display: flex;
  padding: 0 20px 20px 20px;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-top: 0;
}

.bPopWrap.toast .popBtm button {
  display: flex;
  padding: var(--Spacing-Spacing-12, 12px) 16px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex: 1 0 0;
  border-radius: var(--Radius-xl, 16px);
  background: var(--Container-brand-primary, #0a3162);
  color: #fff !important;
}

.bPopWrap.toast .popBtm.twin {
  display: flex;
}

.bPopWrap.toast .popBtm.twin button {
  position: relative;
}

@include rwd("lg") {
  .bPopWrap.w1200 {
    width: auto;
    max-width: calc(100% - #{toRem(100)});
  }
}

@include rwd("md") {
  .bPopWrap.w800,
  .bPopWrap.w820 {
    width: auto;
    max-width: calc(100% - #{toRem(100)});
  }
}

@include rwd("sm") {
  .bPopWrap.wdCtr {
    width: calc(100% - #{toRem(100)});
    min-width: toRem(280);
    max-width: toRem(640);
  }
  .bPopWrap .popTit {
    padding: toRem(32) toRem(24) toRem(20);
  }
  .pTit {
    font-size: toRem(18);
  }
  .pTit .popDescription {
    font-size: toRem(14);
  }
  .bPopWrap .xBtnWrap {
    top: toRem(39);
    right: toRem(25);

    .b-close::before,
    .b-close::after {
      content: "";
      height: toRem(16);
      width: toRem(2);
      position: absolute;
      left: toRem(8);
      top: toRem(3);
      background: $ap-text-black;
    }
  }
  .bPopWrap .popScr {
    max-height: toRem(250);
    min-height: toRem(100);
    padding: 0 toRem(25) toRem(30);
  }
  .bPopWrap .popScr.prM {
    padding: 0 toRem(25) toRem(30);
  }
  .bPopWrap .popBtm {
    padding: toRem(25);
  }
  .bPopWrap .popBtm.noBorder {
    padding: toRem(25) toRem(25) toRem(30);
  }
  .lgMsg {
    position: relative !important;
    bottom: auto !important;
    padding: toRem(4);
  }
}

/***************************
*** closed promotion 팝업 ***
****************************/

.closedPrmPopup.bPopWrap {
  width: toRem(480);

  .pTit {
    padding-right: toRem(0);
    white-space: pre-line;
  }

  header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: toRem(40) toRem(48);
    gap: toRem(24);
  }

  .popTit {
    padding: toRem(0);
  }

  .popText {
    padding-top: toRem(16);
    font-size: toRem(14);
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 25.2px */
    color: #343434;
    font-feature-settings: "clig" off,
    "liga" off;

    /* Body/Body.16R */
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 180%; /* 28.8px */
  }

  .popSubTitle {
    color: #343434;
    padding-top: toRem(14);
    font-weight: 700;
    line-height: 180%;
  }

  .popSubText {
    color: #808080;
    font-size: toRem(14);
    font-style: normal;
    font-weight: 400;
    line-height: 180%; /* 23.4px */
    &:before {
      content: "• ";
      padding-right: toRem(4);
      font-size: toRem(8);
      position: relative;
      top: toRem(-1);
    }
  }

  .xBtnWrap {
    padding-top: toRem(4);
    position: inherit;
    top: inherit;
    right: inherit;
  }

  .popDescription {
    padding: 0 toRem(48) toRem(48);
  }

  .banner {
    padding: toRem(24);
    text-align: center;
    background-color: #fafafa;
  }

  .popScr.prM {
    padding: toRem(24) toRem(48);
    border-top: solid toRem(1) #e5e5e5;
    font-weight: 500;
  }

  .taskButton {
    height: auto;
    padding: toRem(15);
    font-size: toRem(16);
    font-weight: 500;
  }

  .xBtnWrap .b-close {
    transform: scale(0.85);
  }
}

@include rwd("sm") {
  .closedPrmPopup.bPopWrap {
    width: toRem(342);

    header {
      padding: toRem(24);
    }

    .popDescription {
      padding: 0 toRem(24) toRem(24);
    }

    .pTit {
      padding-right: toRem(0);
    }

    .popScr.prM {
      padding: toRem(24);
    }

    .popTit {
      font-size: toRem(18);
      font-weight: 700;
      line-height: 160%; /* 28.8px */
    }

    .popText {
      color: #343434;
      font-size: toRem(14);
      font-weight: 400;
      line-height: 160%; /* 25.2px */
    }

    .popSubTitle {
      font-size: toRem(13);
    }

    .popSubText {
      color: #808080;
      font-size: toRem(13);
      font-weight: 400;
      line-height: 180%; /* 23.4px */
    }

    .taskButton {
      padding: toRem(12) toRem(16);
    }

    .xBtnWrap .b-close {
    }
  }
}


/*****************************************
*** statsig experiment promotion popup ***
******************************************/

.experimentPrmModal {
  display: none;

  .dimmedLayer {
    z-index: 500;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
  }

  .modalContainer {
    overflow: hidden;
    z-index: 600;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: transparent;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    box-shadow: 0px 12px 48px 2px rgba(51, 61, 75, 0.16), 0px 6px 16px 2px rgba(51, 61, 75, 0.08), 0px 2px 4px 2px rgba(51, 61, 75, 0.06);
    inset: auto 0 0 0;
  }

  .modalContent {
    > a {
      display: block;

    }
    img {
      width: 100%;
    }
  }

  .modalFooter {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    background: white;

    .modalBtn {
      flex: 1;
      padding: 10px 14px;
      background-color: #fff;
      border-radius: 16px;
      font-size: 14px;
      font-weight: 500;
      text-align: center;

      &.cancel {
        background: #e2e2e2;
        color: #333;
        margin-right: 10px;
      }

      &.confirm {
        background: #0a3162;
        color: #fff;
        text-decoration: none;
      }
    }
  }
}

@media (min-width: 600px) {
  .experimentPrmModal {
    .modalContainer {
      top: 50%;
      bottom: auto;
      left: 50%;
      transform: translate(-50%, -50%);
      max-width: 384px;
      border-radius: 12px;
    }
  }
}


/************
*** popup ***
************/
.popWrap .popCont {
  display: none;
}

.popWrap .popCont .contWrap {
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  justify-content: end;
  align-items: center;
  background-color: $ap-white;
  z-index: 301;
}

.fPopup .xBtnWrap {
  width: toRem(30);
  height: toRem(30);
  display: flex;
  position: absolute;
  top: toRem(45);
  right: toRem(48);
}

.popCont .popTit {
  padding: toRem(50);
  padding-bottom: 0;
}

.popCont.mvService {
  position: fixed;
  z-index: 9999 !important;
}

/************
*** 로딩바 ***
************/
.loadingBar {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10000;
}

.loadingBar .blind {
  width: 100%;
  height: 100%;
  position: absolute;
  background: $ap-white;
  opacity: 0.5;
  z-index: 1;
}

.loadingBar .bar {
  position: absolute;
  left: calc(50% - #{toRem(31)});
  top: calc(50% - #{toRem(11)});
  z-index: 2;
}

.loadingBar .bar i {
  width: toRem(14);
  height: toRem(22);
  margin-right: toRem(10);
  position: relative;
  background-color: $ap-solid-orange;
  border-radius: toRem(16);
  transition: 0.4s ease;
  animation-duration: 0.9s;
  animation-iteration-count: infinite;
  animation-name: loadingBar;
}

.loadingBar .bar i.scd {
  animation-delay: 0.1s;
  background-color: $ap-solid-darkblue;
}

.loadingBar .bar i.trd {
  margin-right: 0;
  animation-delay: 0.15s;
  background-color: $ap-brown-1;
}

@keyframes loadingBar {
  35% {
    height: toRem(22);
    top: 0;
  }
  70% {
    height: toRem(14);
    top: toRem(22);
  }
  100% {
    height: toRem(22);
    top: 0;
  }
}

/************
*** 로딩바 ***
************/
.customLoadingBar {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10000;
}

.customLoadingBar .blind {
  width: 100%;
  height: 100%;
  position: absolute;
  background: $ap-white;
  opacity: 0.75;
  z-index: 1;
}

.customLoadingBar .bar {
  position: absolute;
  left: calc(50% - #{toRem(31)});
  top: calc(50% - #{toRem(11)});
  z-index: 2;
}

.customLoadingBar .bar i {
  width: toRem(14);
  height: toRem(22);
  margin-right: toRem(10);
  position: relative;
  background-color: $ap-solid-orange;
  border-radius: toRem(16);
  transition: 0.4s ease;
  animation-duration: 0.9s;
  animation-iteration-count: infinite;
  animation-name: loadingBar;
}

.customLoadingBar .bar i.scd {
  animation-delay: 0.1s;
  background-color: $ap-solid-darkblue;
}

.customLoadingBar .bar i.trd {
  margin-right: 0;
  animation-delay: 0.15s;
  background-color: $ap-brown-1;
}

@keyframes customLoadingBar {
  35% {
    height: toRem(22);
    top: 0;
  }
  70% {
    height: toRem(14);
    top: toRem(22);
  }
  100% {
    height: toRem(22);
    top: 0;
  }
}

/*승객번호 font size 추가*/
.csNum {
  font-size: toRem(15);
}

/*여정 관련 css 추가*/
.infoTd {
  display: flex;
  align-items: center;
}

.pt-0 {
  padding-top: toRem(0);
}

/** modalBottomSheet */
#modalBottomSheet {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1000000000;
}

#modalBottomSheet .modalLayout {
  position: absolute;
  background-color: white;
  width: 100%;
  height: auto;
  max-height: calc(100% - 2.5rem);
  bottom: 0;
  left: 0;
  border-radius: toRem(20) toRem(20) 0 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

#modalBottomSheet .modalLayout .header {
  display: flex;
  align-items: start;
  height: auto;
  padding: toRem(20) toRem(16);
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
}

#modalBottomSheet .modalLayout .header .closeButton {
  cursor: pointer;
  width: toRem(42);
  height: toRem(42);
}

#modalBottomSheet .modalLayout .content {
  padding: 0 toRem(16) toRem(24);
  margin-left: 0;
  flex: 1;
  overflow-y: auto;
}

#modalBottomSheet .modalLayout .footer {
  padding: toRem(12) toRem(24) toRem(20);
}

#modalBottomSheet .buttonNormal {
  min-width: toRem(160);
  width: 100%;
  padding: toRem(15) toRem(20);
  border-radius: toRem(16);
  background: #0A3162;

  color: white;
  font-size: toRem(16);
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */

  &.disabled {
    color: rgba(0, 0, 0, 0.20);
    background: rgba(0, 0, 0, 0.05);
  }
}


#modalBottomSheet .modalBottomSheetDescription {
  color: #6D7788;
  font-size: toRem(14);
  font-style: normal;
  font-weight: 600;
  line-height: toRem(22);
}

// ----- 내 예약 > 예약 취소 클릭 시 노출되는 팝업
#modalBottomSheet.tripDetailModal {
  z-index: 1000;

  .header {
    padding: toRem(20) toRem(16) toRem(8);
  }

  .content {
    padding-bottom: toRem(20);
  }

  .footer {
    padding: toRem(12) toRem(16);
  }

  .disabledInput {
    font-size: toRem(16);
    font-style: normal;
    font-weight: 700;
    line-height: toRem(24);
    color: rgba(0, 0, 0, 0.20);
    width: 100%;
  }

  .verificationInputList {
    display: flex;
    flex-direction: column;
    gap: toRem(12);
    li {
      border-bottom: solid 1px #DDD;
      padding: toRem(12) 0;
    }
  }

  .verifyCode {
    color: #222;
    font-size: toRem(16);
    font-style: normal;
    font-weight: 700;
    line-height: toRem(24);
    flex: 1;
    width: 100%;

    &::placeholder {
      color: #9AA2B1;
      font-weight: 400;
    }
  }

  .verificationCodeTimer {
    color: #0093FF;
    font-size: toRem(14);
    font-style: normal;
    font-weight: 500;
    line-height: toRem(22);
  }


  .resendButton {
    padding: toRem(4) toRem(12);
    border-radius: toRem(14);
    border: 1px solid #DDD;
    color: #222;
    font-size: toRem(13);
    font-style: normal;
    font-weight: 700;
    line-height: 150%;

    &.disabled {
      background: #FAFAFA;
      color: #B2B2B2;

    }
  }

  .description {
    color: #9AA2B1;
    font-size: toRem(14);
    font-style: normal;
    font-weight: 400;
    line-height: toRem(22);
    padding: toRem(12) 0 0 0;
  }

  .errorMessage {
    padding: toRem(8) 0;
    color: #F00;
    font-size: toRem(13);
    font-weight: 400;
    line-height: 160%;
  }
}

@media (min-width: 600px) {
  #modalBottomSheet {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #modalBottomSheet .modalLayout .header {
    padding: toRem(24);
  }

  #modalBottomSheet .modalLayout {
    width: fit-content;
    max-height: calc(100% - 5rem);
    position: relative;
    border-radius: toRem(20);
    min-width: toRem(400);
    max-width: toRem(976);
  }

  #modalBottomSheet .modalLayout .footer {
    padding: toRem(12) toRem(16);
  }

  // ----- 내 예약 > 예약 취소 클릭 시 노출되는 팝업
  #modalBottomSheet.tripDetailModal .modalLayout {
    max-width: toRem(400);

    .header {
      padding: toRem(24) toRem(24) toRem(8);
    }

    .content {
      padding: 0 toRem(24) toRem(24);

    }

    .footer {
      padding: toRem(12) toRem(24) toRem(20)
    }
  }
}
